<template lang="pug">
  include /mixins
  +b.d-control-input.--variant_5
    d-control-static-multiselect(
      :multiple='false',
      :options='filter.choices || []'
      v-model='checked'
      :name='filterName'
      :label='filterLabel'
      :input-label='filter.label',
      :placeholder='filter.label',
      :disabled="disabled"
      :searchable="searchable"
      @input='emitSelect($event);submit()'
      :maxHeight="224"
    )
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils'

export default {
  name: 'SimpleSelect',

  props: {
    value: {},
    filter: {},
    filterName: {},
    disabled: {
      default: false,
    },
    filterLabel: {
      default: 'label',
    },
    searchable: {
      default: false,
    },
  },

  data() {
    return {
      checked: '',
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },

  methods: {
    resetValue() {
      this.checked = ''
    },

    submit() {
      const val = this.normalizeTo(this.checked)
      this.$emit('input', val)
    },

    normalizeTo(value) {
      if (!value) return []
      const checked = this.filter.choices
        .filter(el => value.value === el.value)
        .map(el => el.value)
      return checked
    },

    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      /* eslint-disable-next-line */
      const checked = this.filter.choices.find(el => el.value == value)
      return checked
    },

    emitSelect(value) {
      this.$emit('select', value)
    },
  },
}
</script>

/* eslint-disable prefer-destructuring, prefer-const */
import { evolve } from 'ramda'
import qs from 'qs'

const dateFrom = date => new Date(date).toISOString()
export const dateRangeFrom = evolve({ starts: dateFrom, ends: dateFrom })

export function parsePaginationUrl() {
  const { search } = window.location
  if (search.includes('limit') || search.includes('offset')) {
    const limit = search.match(/limit=+(\d+)/)
    const offset = search.match(/offset=+(\d+)/)
    return {
      limit: limit ? Number(limit[1]) : null,
      offset: offset ? Number(offset[1]) : null,
    }
  }
  return {}
}

export function friendlyUrlParser(baseUrl) {
  let pathName = decodeURIComponent(window.location.pathname)
  let zero = 0
  let parsedUrl = {}
  if (pathName.indexOf(baseUrl) > zero) {
    pathName = pathName.split('/page')[0]
    let hashes = pathName.split(baseUrl)
    if (hashes[1]) {
      hashes = hashes[1].split(';')
      hashes.forEach(hash => {
        let [key, val] = hash.split('=')
        parsedUrl[key] = []
        if (val) {
          if (val.indexOf(',') > zero) {
            val = val.split(',')
            val.forEach(v => {
              if (v.indexOf('/') > zero) {
                parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
              } else {
                parsedUrl[key].push(decodeURIComponent(v))
              }
            })
          } else if (val.indexOf('/') > zero) {
            parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
          } else {
            parsedUrl[key].push(decodeURIComponent(val))
          }
        }
      })
    }
  }
  return parsedUrl
}

/**
 * @param {string} url
 */
export const queryStringParser = url => {
  /**
   * Parse url for creating an object of selected attributes
   * `ignoreQueryPrefix` - this option removes the `?` character from url
   */
  const parsed = qs.parse(url, { ignoreQueryPrefix: true, comma: true })

  const prettified = Object.keys(parsed).reduce((acc, key) => {
    if ('limit' !== key && 'offset' !== key) {
      acc[key] = parsed[key]
    }
    return acc
  }, {})
  return prettified
}

import isShallowEqual from '@wordpress/is-shallow-equal'
import FilterUi from '../Ui'

const FILTERS_ORDER_MAP = {
  search: 1,
  input: 2,
  MultipleCheckboxFilter: 3,
  MultipleCheckboxFilterAdmin: 3,
  ModelChoiceFilter: 4,
  MultipleChoiceFilter: 5,
  ChoiceFilter: 6,
  AutocompleteChoiceFilter: 7,
  BooleanFilter: 8,
  BooleanSelect: 9,
  checkbox: 10,
  LocationWidget: 11,
  geo_path: 12,
  geo_path_short: 13,
  geo_path_region: 14,
  ArrayFilter: 15,
  StandardRangeFilter: 16,
  DateFromToRangeFilter: 17,
}

export default {
  props: [
    'loading',
    'value',
    'receive',
    'all',
    'available',
    'pagination',
    'label',
    'needSearch',
  ],

  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }

        this.prepare(filters)
      },
    },
  },

  computed: {
    totalCount() {
      return Object.hasOwnProperty.call(this.pagination, 'total') ? this.pagination.total : this.value.total
    },

    filters() {
      const data = this.all?.items?.filters

      if (!data) return []

      const preparedFilters = Object.values(data).map(el => {
        el.order = FILTERS_ORDER_MAP[el.type]

        if ('place_registration' === el.urlKey) {
          el.optionLabel = 'name'
        }

        return el
      })
      const filteredFilters = preparedFilters.filter(el => 'OrderingFilter' !== el.type)
      const orderedFilters = filteredFilters.sort((a, b) => a.order - b.order)

      return orderedFilters
    },
  },

  created() {
    this.receive({ category: this.label })
  },

  methods: {
    prepare(value) {
      this.prepared = value
    },

    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },

    handleInput(value) {
      this.prepare(value)
    },

    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 })
    },
  },
}

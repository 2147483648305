<template lang="pug">
  include /mixins
  div
    slot(
      name='btn',
      :open='() => showSidebar()',
      :is-open='show'
    )
    transition(name='fade')
      .is-blured(
        v-if='show'
        @click.prevent='showSidebar()'
      )
    transition(name='slide')
      +b#side-menu.side-menu(v-if='show')
        slot(
          :close='() => show = false'
          name='sidebar'
        )
</template>

<script>
export default {
  name: 'UiSidebar',
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showSidebar() {
      this.show = !this.show
      this.$body.dataset.blocked = this.show
    },
  },
}
</script>

<template lang="pug">
  include /mixins.pug
  +b.g-container.--fit_xl
    +b.g-row.--appearance_spaced.--space_xxxl.--align_center
      +b.g-cell.g-cols.--12-xs.--8-xl
        +b.i-card
          +b.ds-panel.--space_xl.--space_2xl-xl
            +e.element.--offset_bottom
              +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_md.--size_2md-md.--size_2lg-xl.--bold {{ _('Bank ID НБУ') }}
          +b.ds-panel.--space_xl.--space_2xl-xl
            +e.element.--offset_bottom
              +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_sm.--size_md-md.--size_2md-xl {{ _('Перевірте дані') }}

          slot

          +b.ds-panel.--space_xl.--space_3xl-xl
            +e.element.--offset_vertical
              +b.LABEL.d-control-checkbox.--variant_flex
                +e.INPUT.element(
                  type='checkbox'
                  v-model='formdata.rules'
                )
                +e.SPAN.label
                +e.SPAN.content
                  +e.SPAN.title {{ _('Даю згоду на передачу та обробку персональних даних') }}
          +b.g-row.--appearance_spaced.--justify_end.--space_xl
            +b.g-cell.g-cols.--12-xs.--6-sm
              +b.BUTTON.control-button.--transparent.--rounded(@click.prevent="back()")
                +e.element
                  +e.SPAN.text {{ _('Відмовитись') }}
            +b.g-cell.g-cols.--12-xs.--6-sm
              +b.BUTTON.control-button.--rounded(@click.prevent="submit()", :disabled="!formdata.rules")
                +e.element
                  +e.SPAN.text {{ _('Продовжити') }}
      +b.g-cell.g-cols.--12-xs.--3-xl
        +b.ds-panel.--space_xl.--space_2xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--color_white.--appearance_center-till-xl.--size_md.--size_2md-md.--size_2lg-xl.--lh_2lg-xl {{ _('Зверніть увагу') }}
        +b.ds-panel.--space_xl.--space_2xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--color_white.--size_2xs.--size_xs-xl.--lh_default {{ _("Ці дані необхідні для того, щоб Ви підтвердили свою особу у системі.") }}
</template>

<script>
export default {
  name: 'AuthBankIdInfo',

  props: ['rejectUrl', 'successUrl'],

  data() {
    return {
      formdata: {
        rules: false,
      },
    }
  },

  methods: {
    back() {
      window.location.assign(this.rejectUrl)
    },
    submit() {
      window.location.assign(this.successUrl)
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.FORM
    +b.tags-block-news-page
      +e.BUTTON.t.check-sbmt(@click.prevent="resetTags")
        +e.LABEL.tag(:class="{'is-active': !checkedTags.length}") {{_('Все')}}
      div(v-for='item in tags' :key='item.slug')
        +e.LABEL.tag(
          :for='item.slug'
          :data-name='item.slug'
          :class="{'is-active': checkedTags.includes(item.slug)}"
        ) {{ _(item.title) }}
        input(
          type='checkbox'
          :id='item.slug'
          name='tags'
          :value='item.slug'
          @change.prevent='getCheck'
          v-model='checkedTags'
          class='check-tag'
        )
</template>
<script>
// import { forEach } from 'lodash'
export default {
  name: 'UiTags',
  props: ['tags'],
  data() {
    return {
      searchString: window.location.search || '',
      checkedTags: [],
    }
  },
  mounted() {
    this.parseUrl()
    // const tagsEl = this.$refs.tags
    // const { allTag } = this.$refs
    // tagsEl.forEach(tag => {
    //   /* eslint-disable yoda */
    //   if (this.checkedTags.indexOf(tag.dataset.name) !== -1) {
    //     tag.classList.add('is-active')
    //     allTag.classList.remove('is-active')
    //   } else {
    //     allTag.classList.toggle('is-active')
    //   }
    // })
  },
  methods: {
    getCheck() {
      const { length } = this.checkedTags
      const { pathname } = window.location
      window.location = length ? `?tags=${this.checkedTags.join(',')}` : pathname
    },

    parseUrl() {
      const nonSearch = this.searchString.replace(/[&]?page=[\d]*/, '')
      const tags = nonSearch.split('?tags=')[1] || ''
      const splitedTags = tags ? tags.split(',') : []
      this.checkedTags = splitedTags
    },

    resetTags() {
      this.checkedTags = []
      this.getCheck()
    },
  },
}
</script>

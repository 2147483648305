<template lang="pug">
  component(
    v-bind="$attrs"
    v-on="$listeners"
    :is="layoutComponent",
    :condencable="condencable",
    :full-width="fullWidth",
    :condenced="condenced",
    :opened="opened",
    @backdrop-click="close"
  )
    template(#content): slot(v-bind="parameters")
    template(#backdrop): slot(name="backdrop", v-bind="parameters")
    template(#element): slot(name="element", v-bind="parameters")

</template>

<script>

import Layout from './Layout.vue';
import { defaultName } from './const';

export default {
  name: 'drawer-container',
  inheritAttrs: false,
  props: {
    name: { default: defaultName },
    layoutComponent: { default: () => Layout },
    condencable: Boolean,
    opened: Boolean,
    condenced: Boolean,
    fullWidth: Boolean,
  },

  provide() {
    return { [this.name]: this };
  },

  watch: {
    $route() {
      if (!this.condencable) {
        this.change('opened', false);
      }
    },
  },

  computed: {
    parameters() {
      return {
        name: this.name,
        condencable: this.condencable,
        condenced: this.condenced,
        opened: this.opened,
        open: this.open,
        close: this.close,
        toggle: this.toggle,
      };
    },
  },

  methods: {
    change(key, value) {
      this.$emit(`update:${key}`, value);
    },

    set(value) {
      if (this.condencable) {
        return this.change('condenced', value);
      }

      return this.change('opened', value);
    },

    open() {
      this.set(true);
    },

    close() {
      this.set(false);
    },

    toggle() {
      this.set(this.condencable ? !this.condenced : !this.opened);
    },
  },
};

</script>

/* eslint-disable */
export default {
  en: {
    hint: 'Click or drag the file here to upload',
    loading: 'Uploading…',
    noSupported: 'Browser is not supported, please use IE10+ or other browsers',
    success: 'Upload success',
    fail: 'Upload failed',
    preview: 'Preview',
    btn: {
      off: 'Cancel',
      close: 'Close',
      back: 'Back',
      save: 'Save'
    },
    error: {
      onlyImg: 'Image only',
      outOfSize: 'Image exceeds size limit: ',
      lowestPx: 'Image\'s size is too low. Expected at least: '
    }
  },
  ru: {
    hint: 'Нажмите, или перетащите файл в это окно',
    loading: 'Загружаю……',
    noSupported: 'Ваш браузер не поддерживается, пожалуйста, используйте IE10 + или другие браузеры',
    success: 'Загрузка выполнена успешно',
    fail: 'Ошибка загрузки',
    preview: 'Предпросмотр',
    btn: {
      off: 'Отменить',
      close: 'Закрыть',
      back: 'Назад',
      save: 'Сохранить'
    },
    error: {
      onlyImg: 'Только изображения',
      outOfSize: 'Изображение превышает предельный размер: ',
      lowestPx: 'Минимальный размер изображения: '
    }
  },
  de: {
    hint: 'Klick hier oder zieh eine Datei hier rein zum Hochladen',
    loading: 'Hochladen…',
    noSupported: 'Browser wird nicht unterstützt, bitte verwende IE10+ oder andere Browser',
    success: 'Upload erfolgreich',
    fail: 'Upload fehlgeschlagen',
    preview: 'Vorschau',
    btn: {
      off: 'Abbrechen',
      close: 'Schließen',
      back: 'Zurück',
      save: 'Speichern'
    },
    error: {
      onlyImg: 'Nur Bilder',
      outOfSize: 'Das Bild ist zu groß: ',
      lowestPx: 'Das Bild ist zu klein. Mindestens: '
    }
  },
  ua: {
    hint: 'Натисніть, або перетягніть файл в це вікно',
    loading: 'Завантажую……',
    noSupported: 'Ваш браузер не підтримується, будь ласка скористайтесь IE10 + або іншими браузерами',
    success: 'Завантаження виконано успішно',
    fail: 'Помилка завантаження',
    preview: 'Попередній перегляд',
    btn: {
      off: 'Відмінити',
      close: 'Закрити',
      back: 'Назад',
      save: 'Зберегти'
    },
    error: {
      onlyImg: 'Тільки зображення',
      outOfSize: 'Зображення перевищує граничний розмір: ',
      lowestPx: 'Мінімальний розмір зображення: '
    }
  },
};
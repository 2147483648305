<template lang='pug'>
  include /mixins
  +b.i-catalog
    +e.sidebar
      catalog-sidebar(
        :filters="filters"
        @update:filters="changeFilters"
        :pagination="pagination",
        :attributesResource='attributesResource',
        :additionalResource="additionalResource"
        :label='label'
        :totalItems="totalItems"
        :needSearch="needSearch"
        :portalStatuses="portalStatuses"
      )
    +e.content
      +b.i-preloader.--variant_2(:class="{'is-active': loading}")
        +e.item.--size_md
      slot(
        :loading="loading"
        :items="result && result.data"
        :pagination="pagination"
        @update:pagination="changePagination"
      )
</template>

<script>

import CatalogSidebar from './Sidebar'

export default {
  components: { CatalogSidebar },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'additionalResource',
    'label',
    'totalItems',
    'needSearch',
    'portalStatuses',
  ],

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.receive(this.parameters)
    },
  },
}

</script>

export function keyedTransformer(
  transformer, from, to = from, transformOnUndefined = false, dflt
) {
  return function fn(source, result) {
    const data = (source && 'undefined' !== typeof source[from] && null !== source[from])
      ? source[from]
      : dflt

    if (!transformOnUndefined && 'undefined' === typeof data) {
      return result
    }

    return {
      ...result,
      [to]: transformer(data),
    }
  }
}

export function symetricalKeyTransformers(
  fromHandler, toHandler, fromKey, toKey = fromKey
) {
  return [
    keyedTransformer(fromHandler, fromKey, toKey),
    keyedTransformer(toHandler, toKey, fromKey),
  ]
}

export function sameTransformer(data) {
  return data
}

export function composeTransformers(...transformers) {
  const prepared = transformers.reverse()

  return function fn(source) {
    return prepared.reduce((acc, transform) => transform(source, acc), {})
  }
}

export const normalizeSelectValue = value => (
  Array.isArray(value) || 'undefined' === typeof value || null === value
    ? (value || [])
    : [value]
)

export const normalizeFiles = files => {
  const arr = files.reduce((acc, el) => {
    const data = `${el.name};${el.src}`
    acc.push(data)
    return acc
  }, [])
  return arr
}

export const getFileName = file => {
  let obj = null
  if (file) {
    const one = 1
    const lastIndexOf = file.lastIndexOf('/') + one
    obj = {
      src: file,
      name: decodeURIComponent(file.substr(lastIndexOf)),
    }
  }
  return obj
}

export const alphabetSorting = (items, key) => items.sort((a, b) => a[key].localeCompare(b[key]))

export const clearPhone = phone => phone.replace(/[^+0-9]/g, '')

export const prettifyPhone = phone => phone.replace(/(\w{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3 $4 $5')

<template lang="pug">
  include /mixins
  d-control-static-input(
    class="d-control-input--variant_5"
    v-model='query'
    prepend-icon="icon-search"
    :name='query'
    autocomplete='off'
    label='caption'
    type='search'
    :placeholder='_("Пошук")',
    @input='submit'
  )
</template>
<script>
import {
  isEmpty,
} from '@aspectus/vue-utils'

export default {
  name: 'SimpleSearch',
  props: ['value'],
  data() {
    return {
      query: '',
    }
  },
  watch: {
    value(nval) {
      this.query = this.normalizeFrom(nval)
    },
  },
  mounted() {
    this.query = this.normalizeFrom(this.value)
  },
  methods: {
    submit() {
      const length = 2
      const zero = 0
      if (this.query.length >= length) {
        this.$emit('input', this.query)
        return
      }
      if (this.query.length === zero) {
        this.$emit('input', null)
      }
    },
    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      const query = value
      return query
    },
  },
}
</script>

export const partnersDots = number => {
  const dots = [...document.getElementsByClassName('region-marker')]
  const map = document.getElementById('ukraine-map')
  if (map) {
    const k = map.clientWidth / map.width.animVal.value
    const regions = map.getElementsByClassName('land')
    regions.forEach(e => e.classList.remove('is-active'))
    dots.forEach(dot => {
      dot.classList.remove('is-active')
      const {
        region,
        slide,
      } = dot.dataset
      // eslint-disable-next-line eqeqeq
      if (slide != number) return
      const parent = document.getElementById(region)
      parent.classList.add('is-active')
      const position = parent.getBBox()
      const left = (position.x + (position.width / 2)) * k
      const top = (position.y + (position.height / 2)) * k
      dot.classList.add('is-active')
      dot.style.setProperty('--left', `${left}px`)
      dot.style.setProperty('--top', `${top}px`)
      // dot
    })
  }
}

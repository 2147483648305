import {
  createResource,
  postResource,
} from '@resource/resource'
import { simpleTransformer } from '@resource/transformer'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'auth'

export const USER_LOGIN = prefixAPI('/login/', MODEL_PREFIX)
export const USER_LOGOUT = prefixAPI('/logout/', MODEL_PREFIX)
export const USER_PASSWORD_RESET = prefixAPI('/password/reset/', MODEL_PREFIX)
export const USER_PASSWORD_CONFIRM = prefixAPI('/password/reset/confirm/', MODEL_PREFIX)
export const USER_PASSWORD_SET = prefixAPI('/password/reset/confirm/', MODEL_PREFIX)
export const USER_PASSWORD_CHANGE = prefixAPI('/password/change/', MODEL_PREFIX)
export const REGISTER_INVESTOR = prefixAPI('/register/investor/', MODEL_PREFIX)
export const REGISTER_OWNER = prefixAPI('/register/owner/', MODEL_PREFIX)

export const userLoginResource = createResource(USER_LOGIN, postResource)
export const userLogoutResource = createResource(USER_LOGOUT, postResource, simpleTransformer)
export const passwordResetResource = createResource(USER_PASSWORD_RESET, postResource)
export const passwordConfirmResource = createResource(USER_PASSWORD_CONFIRM, postResource)
export const passwordSet = createResource(USER_PASSWORD_SET, postResource)
export const passwordChangeResource = createResource(USER_PASSWORD_CHANGE, postResource)
export const registerInvestor = createResource(REGISTER_INVESTOR, postResource)
export const registerOwner = createResource(REGISTER_OWNER, postResource)

<template lang="pug">
  include /mixins
  +b.d-control-input.--variant_5
    d-control-static-multiselect(
      :multiple='true',
      :options='filter.choices || []'
      v-model='checked'
      :name='filterName'
      :label='filterLabel'
      :input-label='filter.label',
      :placeholder='filter.label',
      :disabled="disabled"
      :searchable="searchable"
      track-by="value"
      @input='emitSelect($event);submit()'
      :maxHeight="224"
    )
      template(
        slot="selection"
        slot-scope="{ values, search }"
        v-if="values.length"
      )
        +b.SPAN.multiselect__single
          | {{ values.map(el => el.label).join(', ') }}
        +b.i-count.--variant_absolute
          +e.text {{ values.length }}
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils'

export default {
  name: 'SimpleSelect',

  props: {
    value: {},
    filter: {},
    filterName: {},
    disabled: {
      default: false,
    },
    filterLabel: {
      default: 'label',
    },
    searchable: {
      default: false,
    },
  },

  data() {
    return {
      checked: [],
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },

  methods: {
    resetValue() {
      this.checked = []
    },

    submit() {
      const val = this.normalizeTo(this.checked)

      this.$emit('input', val)
    },

    normalizeTo(value) {
      if (!value) return []

      const checked = this.filter.choices
        /* eslint-disable-next-line */
        .filter(el => value.find(v => v.value == el.value))
        .map(el => el.value)

      return checked
    },

    normalizeFrom(value) {
      let val = value

      if (isEmpty(val)) return []

      if (!Array.isArray(val)) {
        val = [val]
      }

      const checked = this.filter.choices
        /* eslint-disable-next-line */
        .filter(el => val.find(v => v == el.value))

      return checked
    },

    emitSelect() {
      this.$emit('select', this.checked)
    },
  },
}
</script>

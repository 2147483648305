/* eslint-disable */
/**
 * return tree view ,
 * set 4 spaces before initital string if it has depth
 * @param {string} string - text in select label
 * @param {number} by - depth
 */

// import Vue from 'vue'
import Inputmask from 'inputmask'

/**
 * @param {string} path - name of key(
 *  f.e. Object is -{key: {child_key: 'test'}}, path - key, child_key
 * )
 */
export function pathGetter(path) {
  if (!Array.isArray(path)) {
    path = Array.from(arguments)
  }

  return item => path.reduce((acc, x) => (acc ? acc[x] : '-'), item)
}

export class Field {
  constructor(key, label = null, getter = null, parameters = {}) {
    if (null !== label && is.function(label)) {
      label = null
      getter = label
      parameters = getter
    }

    if (null !== getter && is.not.function(getter)) {
      getter = null
      parameters = getter
    }

    this.key = key
    this.label = label || this.key.toTitle()
    this.getter = getter || pathGetter(this.key)
  }

  getValue(obj) {
    return this.getter(obj)
  }
}

export function mask(inputObject, number) {
  if (inputObject.selectionStart) {
    inputObject.setSelectionRange(number, number)
    inputObject.focus()
  }
  const phonemask = new Inputmask({ mask: '+38(999)999-9999', greedy: false })
  phonemask.mask(inputObject)
}

export function masking(name, $this) {
  let zero = 0
  let reg = /\d+/
  let events = ['click', 'focus', 'keyup']
  events.forEach(event => {
    let root = $this.$refs[name]
    if (!root) return
    if (root[0]) {
      root = root[0]
    }
    root.$el.addEventListener(event, e => {
      let numb = e.target.value.match(reg)
      if (numb) {
        mask(e.target, numb[zero].length)
      } else {
        mask(e.target, zero)
      }
    })
  })
}

/**
 * simple debounce 
 * @param {function} f - fn for callback
 * @param {number} ms - time for delay
 */
export function debounce(f, ms) {
  let isCooldown = false

  // eslint-disable-next-line func-names
  return function () {
    if (isCooldown) return

    f.apply(this, arguments)

    isCooldown = true

    setTimeout(() => {
      isCooldown = false
      return false
    }, ms)
  }
}

export function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  const eleven = 11;
  if (match && match[2].length === eleven) {
    return match[2];
  }
  return new Error('error parsed link');
}

export const documentOffsetTop = el => {
  return el.offsetParent.offsetTop
}

export function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
<template lang="pug">
  include /mixins
  +b.modal--variant_light
    +b.close-popup.--variant_light(
      @click.prevent='$emit("close")'
    )
      +b.I.icon-close
    +b.P.ds-caption.--size_md.--size_lg-xl.--bold.--color_dark(v-if="title") {{ title }}
    +b.ds-panel--space_lg(v-if="subtitle")
      +e.element--offset_top
        +b.P.ds-caption.--size_4xs.--size_xs-sm.--size_sm-lg.--color_dark {{ subtitle }}
    +b.ds-panel--space_2xl
      +e.element--offset_top
        +b.g-row.--appearance_spaced
          +b.g-cell.g-cols.--6-xs
            +b.BUTTON.control-button.--transparent(@click.prevent="$emit('close')")
              +e.element
                +e.SPAN.text {{ cancelButtonText || _('Ні!') }}
          +b.g-cell.g-cols.--6-xs
            +b.BUTTON.control-button(@click.prevent="confirm")
              +e.element
                +e.SPAN.text {{ confirmButtonText || _('Так!') }}
</template>

<script>
export default {
  name: 'UiConfirmModal',

  props: {
    title: {},
    subtitle: {},
    info: {},
    callback: {},
    cancelButtonText: {},
    confirmButtonText: {},
  },

  methods: {
    confirm() {
      const timeout = 200
      this.$emit('close')
      setTimeout(() => {
        this.callback(this.info)
      }, timeout)
    },
  },
}
</script>

import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')
const Display = () => import('./views/Display')
const Info = () => import('./views/Info')

const ROUTE_NAME = 'profile:reservation:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const DISPLAY = p('display')
const CREATE = p('create')
const INFO = p('info')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(['RESERVATION:RESERVATION:VIEW'], options),
    }),
    route('create/', Display, CREATE, {
      meta: accessPermission(['RESERVATION:RESERVATION:ADD'], options),
    }),
    route('info/', Info, INFO, {
      props: true,
      meta: accessPermission(['RESERVATION:RESERVATION:ADD'], options),
    }),
    route(':id/', Display, DISPLAY, {
      props: true,
      meta: accessPermission(['RESERVATION:RESERVATION:ADD'], options),
    }),
    { path: '*', redirect: { name: LIST } },
  ]
}

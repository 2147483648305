import {
  baseResource,
  createResource,
  postResource,
  patchResource,
  optionsResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'
import { friendlyUrlGenerator } from '@utils/friendly'

const MODEL_PREFIX = 'sale-request'

export const SALE_REQUEST_CREATE = prefixAPI('/create/', MODEL_PREFIX)
export const SALE_REQUEST_LIST = prefixAPI('/list/', MODEL_PREFIX)
export const SALE_REQUEST_RETRIEVE_UPDATE = prefixAPI('/{id}/retrieve-update/', MODEL_PREFIX)
export const SALE_REQUEST_STATUSES = prefixAPI('/statuses/', MODEL_PREFIX)

export const saleRequestCreateResource = createResource(SALE_REQUEST_CREATE, postResource)
export const saleRequestListResource = friendlyUrlGenerator([
  SALE_REQUEST_LIST, '',
], baseResource)
export const saleRequestUpdateResource = createResource(SALE_REQUEST_RETRIEVE_UPDATE, patchResource)
export const saleRequestRetrieveResource = createResource(SALE_REQUEST_RETRIEVE_UPDATE)
export const saleRequestStatusesResource = createResource(SALE_REQUEST_STATUSES)
export const saleRequestFiltersResource = createResource(SALE_REQUEST_LIST, optionsResource)

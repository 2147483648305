import { updateValue } from '@aspectus/selection-controller';
import { renderSlim } from '@aspectus/vue-utils';
import { Tag } from '@aspectus/vue-tag';

const removeNulls = items => items.filter(x => null !== x);

export default {
  name: 'vue-all-selection-controller',
  props: {
    items: Array,
    amount: {
      type: Number,
      default: 0,
    },
    keyGetter: Function,
  },
  data() {
    return {
      isTotal: false,
      excluded: [],
      selected: [],
    };
  },
  computed: {
    excludedIds() {
      return this.idsMap(this.excluded);
    },
    selectedIds() {
      return this.idsMap(this.selected);
    },
    visuallySelected() {
      if (this.isTotal) {
        return this.items.filter(this.notExcluded);
      }

      return this.selected;
    },
    context() {
      const {
        changeSelected, keyGetter, isTotal, changeTotal, checkSelected,
        visuallySelected, excluded,
      } = this;
      const selectedAmount = isTotal
        ? this.amount - this.excluded.length
        : this.selected.length;
      const isAll = isTotal
        ? 0 === this.excluded.length
        : (this.items && (this.selected.length === this.items.length));
      const isSelected = isTotal || 0 < this.selected.length;

      return {
        selectedAmount,
        isAll,
        isSelected,

        isTotal,
        changeTotal,

        excluded,
        selected: visuallySelected,
        changeSelected,

        keyGetter,
        checkSelected,
      };
    },
  },
  watch: {
    context: {
      immediate: true,
      handler: 'update',
    },
  },
  methods: {
    idsMap(elements) {
      return elements.reduce((acc, x) => {
        if (!x) {
          return acc;
        }

        acc[this.keyGetter(x)] = true;

        return acc;
      }, {});
    },
    notExcluded(item) {
      return !this.excludedIds[this.keyGetter(item)];
    },
    changeTotal(value) {
      this.changeSelected([], false);
      this.isTotal = value;
    },
    changeSelected(value) {
      if (Array.isArray(value) && 0 === value.length) {
        this.isTotal = false;
        this.excluded = [];
      }

      const key = this.isTotal ? 'excluded' : 'selected';
      this[key] = removeNulls(updateValue(
        value, this[key], this.keyGetter, true
      ));
    },
    update(value = this.context) {
      this.$emit('update', value);
    },
    checkSelected(item) {
      if (this.isTotal) {
        return this.notExcluded(item);
      }

      if (item.initialSelected) {
        this.selected.push(item)

        item.initialSelected = false
      }

      return !!this.selectedIds[this.keyGetter(item)];
    },
  },
  render(h) {
    return renderSlim(this.$scopedSlots.default(this.context), h, Tag);
  },
};

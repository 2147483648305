<template lang="pug">
  include /mixins.pug

  +b.g-container.--variant_tiny
    +b.g-row.--appearance_spaced.--space_xxxl
      +b.g-cell.g-cols.--12-xs.--7-xl
        +b.g-row.--appearance_spaced.--space_2xl-xl
          +b.g-cell.g-cols.--12-xs.--6-sm.align-stretch(v-for="item in types")
            +b.i-card.--offset_lg.align-height
              +b.LABEL.d-control-radio.--variant_flex
                +e.INPUT.element(
                  type='radio'
                  :value='item.label'
                  @change="typeChanged"
                  v-model='currentType'
                )
                +e.SPAN.label
                +e.SPAN.content
                  +e.SPAN.title(:class="{'is-active': currentType === item.label}") {{ item.title }}
                  +e.SPAN.description {{ item.description }}
      +b.g-cell.g-cols.--12-xs.--7-xl
        auth-investor-form(
          v-if="$options.INVESTOR === currentType"
          :auth-url="authUrl"
          :bankid-url="bankidUrl"
          :policy-url="policyUrl"
        )
        +b.i-card(v-if="$options.OWNER === currentType")
          +b.ds-panel.--space_xl.--space_3xl-xl
            +e.element.--offset_bottom
              +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_md.--size_2md-md.--size_2lg-xl {{ _('Заявка на продаж земельної ділянки') }}
          land-sale-request-form(:is-register="true")
      +b.g-cell.g-cols.--12-xs.--5-xl(v-if="$options.INVESTOR === currentType")
        +b.ds-panel.--space_xl.--space_2xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--color_white.--appearance_center-till-xl.--size_md.--size_2md-md.--size_2lg-xl.--lh_2lg-xl {{ _('Як це працює') }}
        +b.P.ds-caption.--color_white.--size_2xs.--size_xs-xl.--lh_default {{ _("Як це працює текст") }}
      +b.g-cell.g-cols.--12-xs.--5-xl(v-if="$options.OWNER === currentType")
        +b.ds-panel.--space_xl.--space_2xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--color_white.--appearance_center-till-xl.--size_md.--size_2md-md.--size_2lg-xl.--lh_2lg-xl {{ _('Як це працює інвестор') }}
        +b.P.ds-caption.--color_white.--size_2xs.--size_xs-xl.--lh_default {{ _("Як це працює текст інвестор") }}
</template>

<script>
import { setCookie } from '@utils/cookies'

const INVESTOR = 'investor'
const OWNER = 'owner'

export default {
  INVESTOR,
  OWNER,

  name: 'AuthRegister',

  props: {
    authUrl: {},
    bankidUrl: {},
    policyUrl: {},
  },

  data() {
    return {
      currentType: INVESTOR,
      types: [
        {
          label: INVESTOR,
          title: this._('Я інвестор'),
          description: this._('Я інвестор description'),
        },
        {
          label: OWNER,
          title: this._('Я власник земельної ділянки'),
          description: this._('Я власник земельної ділянки description'),
        },
      ],
    }
  },

  methods: {
    typeChanged() {
      setCookie('register_user_type', this.currentType)
    },
  },
}
</script>

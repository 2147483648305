/* eslint-disable */ 
export const timeline = number => {
  const titles = [...document.getElementsByClassName('who-year-wrap')]
  const texts = [...document.getElementsByClassName('who-paragraphs')]
  titles.forEach(t => t.classList.remove('is-active'))
  texts.forEach(t => t.classList.remove('is-active'))
  titles[number].classList.add('is-active')
  const nestedCards = texts.filter(el => el.dataset.id == number)
  nestedCards.forEach(card => card.classList.add('is-active'))
}

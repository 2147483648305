/* eslint-disable */
const key = (prefix, name) => `${prefix}:${name}`;

export const LocalStorageControllerMixin = {
  props: {
    localStorageKey: { default: 'local-storage-drawer-controller' },
    condenceChecker: { default: false },
    condencable: { type: Boolean },
  },

  data() {
    return {
      willCondence: false,
      isOpened: false,
      isCondenced: JSON.parse(localStorage.getItem(key(this.localStorageKey, 'condenced'))),
    };
  },

  created() {
    this.setCurrentCondenceAbility(
      this.getCurrentCondenceAbility(window.innerWidth)
    );
  },

  mounted() {
    window.addEventListener('resize', this.eventHandler);
  },

  methods: {
    eventHandler(e) {
      this.sizeChanged(e.target.innerWidth);
    },

    getCurrentCondenceAbility(size) {
      if (
        this.condencable &&
        this.condenceChecker
      ) {
        return this.condenceChecker(size);
      }

      return this.willCondence || false;
    },

    setCurrentCondenceAbility(value) {
      this.isOpened = value;
      this.willCondence = value;
    },

    sizeChanged(size) {
      const willCondence = this.getCurrentCondenceAbility(size);

      if (willCondence != this.willCondence) {
        this.setCurrentCondenceAbility(willCondence);
      }
    },

    setCondenced(value, store = false) {
      this.isCondenced = value;

      if (store) {
        localStorage.setItem(key(this.localStorageKey, 'condenced'), JSON.stringify(value));
      }
    },

    updateOpened(value) {
      this.isOpened = value;
    },

    updateCondenced(value) {
      this.setCondenced(value, true);
    }
  },
};

export const withLocalStorageController = Component => ({
  mixins: [LocalStorageControllerMixin],
  inheritAttrs: false,
  render(h) {
    return h(Component, {
      attrs: Object.assign({}, this.$attrs, {
        opened: this.isOpened,
        condenced: this.isCondenced,
        condencable: this.willCondence,
      }),
      on: Object.assign({}, this.$listeners, {
        'update:opened': this.updateOpened,
        'update:condenced': this.updateCondenced,
      }),
      scopedSlots: this.$scopedSlots,
    });
  },
});

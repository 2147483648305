import CabinetHeader from './Header'
import LanguageSwitch from './LanguageSwitch'
import CurrencySwitch from './CurrencySwitch'
import UiSearch from './UiSearch'
import UserBar from './UserBar'
import PriceNormalizer from './PriceNormalizer'
import AddCartItem from './AddCartItem'
import PriceField from './PriceField'
import TableStickyHeader from './TableStickyHeader'
import RouterBackLink from './RouterBackLink'
import TableScroller from './TableScroller'
import Tabs from './Tabs'

export default function install(Vue) {
  Vue.component('cabinet-header', CabinetHeader)
  Vue.component('language-switch', LanguageSwitch)
  Vue.component('currency-switch', CurrencySwitch)
  Vue.component('ui-search', UiSearch)
  Vue.component('user-bar', UserBar)
  Vue.component('price-normalizer', PriceNormalizer)
  Vue.component('add-cart-item', AddCartItem)
  Vue.component('price-field', PriceField)
  Vue.component('table-sticky-header', TableStickyHeader)
  Vue.component('router-back-link', RouterBackLink)
  Vue.component('table-scroller', TableScroller)
  Vue.component('profile-tabs', Tabs)
}

<script>
export default {
  name: 'UiAccordionController',
  data() {
    return {
      current: 0,
      height: null,
    }
  },
  methods: {
    change(n) {
      this.current = n
    },
    checkMaxHeight(h, index) {
      h += 20
      if (Number(index) === this.current) this.$el.style.minHeight = `${h}px`
    },
  },
  render() {
    return this.$scopedSlots.default({
      rememberThis: number => this.change(number),
      current: this.current,
      checkMaxHeight: (h, i) => this.checkMaxHeight(h, i),
    })
  },
}
</script>

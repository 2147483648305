<template lang="pug">
  include /mixins.pug
  +b.ds-section.--space_xl.--space_4xl-xl.--bg_cover.--fullscreen(:style='`background-image: url("${backgroundUrl}")`')
    +e.element.--offset_vertical
      slot
</template>

<script>
export default {
  name: 'AuthPageContainer',

  props: {
    backgroundUrl: {},
  },
}
</script>

import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'
import VueClipboard from 'vue-clipboard2'
import Notifications from 'vue-notification'
import Router from 'vue-router'
import { Store } from 'vuex'

import { extend } from 'vee-validate'
import {
  required, confirmed, email,
  min, min_value as minValue, max, max_value as maxValue,
  ext, size,
} from 'vee-validate/dist/rules'

import '@utils/events'
import * as VueGoogleMaps from 'vue2-google-maps'
import { install } from '@/install'
import { createApp } from '@/app'
import '@/js/validation'
import { install as installFilters } from '@utils/filters'
import appComponents from './js/components'
import '@/styles/index.sass'

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: window.googleMapKey,
    libraries: 'places',
  },
})

Vue.use(installFilters)
Vue.use(install)
Vue.use(appComponents)
Vue.use(VueClipboard)
Vue.use(Notifications)

const { app } = createApp({
  Vue,
  I18n,
  Router,
  Store,
})

app.$mount('#app')

extend('numeric', { ...required, message: app._('Поле должно быть числом.') });
extend('required', { ...required, message: app._('This field is required.') });
extend('confirmed', { ...confirmed, message: app._('Passwords must match.') });
extend('min', { ...min, message: (e, a) => `${app._('Количество символов должно быть не меньше')} ${a.length}` })
extend('min_value', { ...minValue, message: app._('Minimum value is {min}.') });
extend('max', { ...max, message: (e, a) => `${app._('Количество символов не должно превышать')} ${a.length}` })
extend('max_value', { ...maxValue, message: app._('Maximum value is {max}.') });
extend('ext', { ...ext, message: app._('Invalid file format') });
extend('email', { ...email, message: app._('Email is invalid.') });
extend('size', { ...size, message: (e, a) => app._('The file size must be less than ') + a.size / 1024 + app._('Mb') });

const MOBILEREG = /^[+() -\d]+$/

extend('phone', {
  validate(value) {
    // Regex for a international phone number
    return MOBILEREG.test(value)
  },
  message: app._('Phone is not valid'),
})

extend('emailMethod', {
  validate(value) {
    return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ\+0-9_.-]{2,64}@[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ0-9_.-]+[.a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ]{0,6}$/.test(value)
  },
  message() {
    return app._('Поле должно быть действительным электронным адресом.')
  },
})

extend('edrpou_legal', {
  validate(value) {
    return /^\d{8}$/.test(value) || /^\d{9}$/.test(value) || /^\d{12}$/.test(value)
  },
  message() {
    return app._('РНОКПП юр. лица имеет не валидный формат.')
  },
})

extend('edrpou_individual', {
  validate(value) {
    return /^\d{10}$/.test(value)
  },
  message() {
    return app._('РНОКПП физ. лица имеет не валидный формат.')
  },
})

import { prettifyPhone } from '@utils/transformers'

export const prettifyPrice = p => (p || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')
export const normalizeDate = date => (
  /* eslint-disable-next-line */
  date instanceof Date && !isNaN(date) && date || new Date()
)
export const parseDate = date => normalizeDate(new Date(date))
export const formatDate = date => date && date.toLocaleString('ru-Ru', {})
export const localizeDate = value => value.replace(/\,/g, ' ')
export const dateWithoutTime = value => value.split(' ')[0]

export const install = Vue => {
  Vue.filter('prettifyPhone', prettifyPhone)
  Vue.filter('prettifyPrice', prettifyPrice)
  Vue.filter('normalizeDate', normalizeDate)
  Vue.filter('parseDate', parseDate)
  Vue.filter('formatDate', formatDate)
  Vue.filter('localizeDate', localizeDate)
  Vue.filter('dateWithoutTime', dateWithoutTime)
}

export default { install }

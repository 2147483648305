import { renderSlim } from '@aspectus/vue-utils'
import { Tag } from '@aspectus/vue-tag'

export default {
  name: 'user-access-state',
  props: {
    permissions: Array,
    excludedPermission: String,
    permission: String,
    conjoined: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      hasAccess: false,
    };
  },

  mounted() {
    this.checkers()
  },

  methods: {
    checkPermissions() {
      return this.permissions.reduce((acc, p) => {
        acc.push(this.$userPermissions.includes(p))
        return acc
      }, [])
    },

    checkers() {
      if (this.permissions) {
        const allowedPermissions = this.checkPermissions()
        const method = this.conjoined ? 'every' : 'some'
        if (this.excludedPermission) {
          if (this.$userPermissions.includes(this.excludedPermission)) {
            this.hasAccess = false
          } else {
            this.hasAccess = allowedPermissions[method](el => el)
          }
        } else {
          this.hasAccess = allowedPermissions[method](el => el)
        }
      }
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      hasAccess: this.hasAccess,
    }) || [], h, Tag)
  },
}

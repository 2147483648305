<script>

import {
  documentOffsetTop,
} from '@utils/helpers'

export default {
  name: 'UiAccordion',
  props: {
    active: {
      default: () => false,
    },
    collapsable: {
      default: () => false,
    },
    id: { },
    fullHeight: {
      default: () => false,
    },
    init: {
      default: () => true,
    },
    options: {

    },
    index: {},
    checkParentHeight: {},

  },
  data() {
    return {
      activeState: this.active,
      windWidth: 1200,
      one: 1,
      zero: 0,
      contentHeight: 0,
      isDesktop: false,
    }
  },
  computed: {
    style() {
      return {
        icon: this.activeState ? 'mdi-chevron-up' : 'mdi-chevron-down',
        active: this.activeState ? this.one : this.zero,
        myHeight: this.activeState ?
          this.stylesGenerator(this.contentHeight) : this.zero,
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.$nextTick(() => {
          this.calcHeight()
        })
      },
    },
  },
  mounted() {
    this.checkUrl()
    this.resizeHandler()
    this.calcHeight()
    this.initialCheck()
    this.checkWidth()
  },
  methods: {
    stylesGenerator(val) {
      if (this.fullHeight && !this.isDesktop) {
        return 'initial'
      }
      return `${val}px`
    },
    initialCheck() {
      this.activeState = this.init
    },
    calcHeight() {
      const [el] = this.$el.getElementsByClassName('accordion-description')
      if (el) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.contentHeight = el.scrollHeight
            if (this.checkParentHeight) this.checkParentHeight(this.contentHeight, this.index)
          })
        })
      }
    },
    checkWidth() {
      if (window.innerWidth > this.windWidth) {
        this.isDesktop = true
      }
    },
    resizeHandler() {
      if (window.innerWidth < this.windWidth) {
        this.activeState = false
      } else {
        this.activeState = true
        this.isDesktop = true
      }
    },
    toggleActive(optional) {
      if ('resizable' === optional) {
        if (window.innerWidth > this.windWidth) {
          return
        }
      }
      this.activeState = !this.activeState
      this.calcHeight()
      this.checkWidth()
      this.scrolly(500)
    },
    checkUrl() {
      /* eslint-disable no-restricted-globals */
      let { hash } = location
      if (hash) {
        hash = hash.split('#')
        hash = hash[this.one]
        if (hash === this.id) {
          this.activeState = true
        }
      }
    },
    async scrolly(time) {
      setTimeout(async () => {
        const offset = 90
        // console.dir(document.getElementById(this.id));
        const top = documentOffsetTop(document.getElementById(this.id), this.initialHeight) - offset
        window.scrollTo({ top, behavior: 'smooth' })
      }, time)
    },
  },
  render() {
    return this.$scopedSlots.default({
      style: this.style,
      activeState: this.activeState,
      toggleActive: this.toggleActive,
      isDesktop: this.isDesktop,
      collapse: () => {
        this.activeState = false
      },
      recalc: () => this.calcHeight(),
      index: this.index,
    })
  },
}
</script>

import installer from '@aspectus/vue-route-installer'
import { route, prefixRoutes } from '@md/routing'
// import { accessMeta } from '@aspectus/vue-router-permissions'
// import { toBeAuthenticated } from '@md/users/permissions'

import { createRoutes as createSettingsRoutes } from './modules/settings/router'
import { createRoutes as createMapRoutes } from './modules/map/router'
import { createRoutes as createAnnouncementRoutes } from './modules/announcement/router'
import { createRoutes as createManagerRoutes } from './modules/manager/router'
import { createRoutes as createOrdersRoutes } from './modules/orders/router'
import { createRoutes as createHoldingRoutes } from './modules/holding/router'
import { createRoutes as createNotificationRoutes } from './modules/notification/router'
import { createRoutes as createCooperationRoutes } from './modules/cooperation/router'
import { createRoutes as createNotaryRoutes } from './modules/notary/router'
import { createRoutes as createRepresentativeRoutes } from './modules/representative/router'
import { createRoutes as createInvestorRoutes } from './modules/investor/router'
import { createRoutes as createLandRoutes } from './modules/land/router'
import { createRoutes as createWishlistRoutes } from './modules/wishlist/router'
import { createRoutes as createBargainRoutes } from './modules/bargain/router'
import { createRoutes as createReservationRoutes } from './modules/reservation/router'
import { createRoutes as createAutoApplicationRoutes } from './modules/auto-application/router'
import { createRoutes as createSaleRequestRoutes } from './modules/sale-request/router'

const CabinetInstaller = () => import('./components')
const Cabinet = () => import('./views/Cabinet')

export function createRoutes(options) {
  const { Vue } = options
  return [
    route('', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: [
        prefixRoutes('', createSettingsRoutes(options)),
        prefixRoutes('map/', createMapRoutes(options)),
        prefixRoutes('announcement/', createAnnouncementRoutes(options)),
        prefixRoutes('manager/', createManagerRoutes(options)),
        prefixRoutes('orders/', createOrdersRoutes(options)),
        prefixRoutes('holding/', createHoldingRoutes(options)),
        prefixRoutes('notification/', createNotificationRoutes(options)),
        prefixRoutes('cooperation/', createCooperationRoutes(options)),
        prefixRoutes('notary/', createNotaryRoutes(options)),
        prefixRoutes('representative/', createRepresentativeRoutes(options)),
        prefixRoutes('investor/', createInvestorRoutes(options)),
        prefixRoutes('land/', createLandRoutes(options)),
        prefixRoutes('wish/', createWishlistRoutes(options)),
        prefixRoutes('bargain/', createBargainRoutes(options)),
        prefixRoutes('reservation/', createReservationRoutes(options)),
        prefixRoutes('auto-application/', createAutoApplicationRoutes(options)),
        prefixRoutes('sale-request/', createSaleRequestRoutes(options)),
      ],
      // meta: accessMeta(toBeAuthenticated, { name: 'auth:login' }, options),
    }),
  ]
}

import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'

import BoundLabel from './BoundLabel'
import ErrorComponent from './ErrorComponent'
import MultiSelect from './Multiselect'
import Datepicker from './DatePicker'
import Checkbox from './Checkbox'
import StaticLabel from './StaticLabel'
// import Editor from './Editor'
// import DateRange from './DateRange'
import UnifiedControlElement from './UnifiedControlDescriptor'
import UnifiedControlDescriptorBase from './UnifiedControlDescriptorBase'
import PrependComponent from './PrependComponent'
import AppendComponent from './AppendComponent'

const composerForInput = (input, label = BoundLabel, base = UnifiedControlElement) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
  prependComponent: () => PrependComponent,
  appendComponent: () => AppendComponent,
})(base)

const composer = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  errorComponent: () => ErrorComponent,
  bails: true,
  controllerComponent: () => NoneController,
  prependComponent: () => PrependComponent,
  appendComponent: () => AppendComponent,
})(Descriptor)
// input with bound label
const InputWithLabel = composerForInput('Input', BoundLabel, UnifiedControlDescriptorBase)
const ControlInput = composer(InputWithLabel)
// input with static input
const InputWithStaticLabel = composerForInput('Input', StaticLabel)
const ControlStaticInput = composer(InputWithStaticLabel)
// multiselect
const MultiselectWithLabel = composerForInput('MultiSelect', BoundLabel, UnifiedControlDescriptorBase)
const ControlMultiselect = composer(MultiselectWithLabel)
// multiselect
const MultiselectStaticLabel = composerForInput('MultiSelect', StaticLabel)
const ControlStaticMultiselect = composer(MultiselectStaticLabel)
// textarea
const TextareaWithLabel = composerForInput('Textarea', BoundLabel, UnifiedControlDescriptorBase)
const ControlTextarea = composer(TextareaWithLabel)
// textarea
const TextareaStaticLabel = composerForInput('Textarea', StaticLabel)
const ControlStaticTextarea = composer(TextareaStaticLabel)
// flatpickr
const DatePickerWithLabel = composerForInput('Datepicker', BoundLabel, UnifiedControlDescriptorBase)
const ControlDatePicker = composer(DatePickerWithLabel)
// flatpickr
const DatePickerStaticLabel = composerForInput('Datepicker', StaticLabel)
const ControlStaticDatePicker = composer(DatePickerStaticLabel)
// // Trumbowyg
// const EditorWithLabel = composerForInput('Editor')
// const ControlEditor = composer(EditorWithLabel)
// daterange
// const DateRangeWithLabel = composerForInput(DateRange)
// const ControlDateRange = composer(DateRangeWithLabel)
export default function install(Vue, { prefix = 'DControl' } = {}) {
  // Vue.component('Editor', Editor)
  Vue.component('MultiSelect', MultiSelect)
  Vue.component('Datepicker', Datepicker)
  Vue.component('BoundLabel', BoundLabel)
  Vue.component('ErrorComponent', ErrorComponent)
  // Vue.component(`${prefix}Editor`, ControlEditor)
  Vue.component(`${prefix}Input`, ControlInput)
  Vue.component(`${prefix}StaticInput`, ControlStaticInput)
  Vue.component(`${prefix}Multiselect`, ControlMultiselect)
  Vue.component(`${prefix}StaticMultiselect`, ControlStaticMultiselect)
  Vue.component(`${prefix}Textarea`, ControlTextarea)
  Vue.component(`${prefix}StaticTextarea`, ControlStaticTextarea)
  Vue.component(`${prefix}Datepicker`, ControlDatePicker)
  Vue.component(`${prefix}StaticDatepicker`, ControlStaticDatePicker)
  // Vue.component(`${prefix}DateRange`, ControlDateRange)
  Vue.component(`${prefix}Checkbox`, Checkbox)
  Vue.component('PrependComponent', PrependComponent)
  Vue.component('AppendComponent', AppendComponent)
}

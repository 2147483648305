<template lang="pug">
  image-cropper(
    v-model="isVisible"
    @input="$emit('close')"
    @crop-success="cropSuccess"
    @src-file-set="srcFileSet"
    v-bind="options"
    :max-size="maxSize"
    :no-circle="false"
    :no-square="true"
    :lang-type="langType"
    :lang-ext="uk"
    :img-format="imageFormat"
    :max-size-display="maxSizeDisplay"
  )
</template>

<script>
import ImageCropper from '@/js/packages/vue-image-cropper/ImageCropper'

export default {
  name: 'UiCropper',

  components: {
    ImageCropper,
  },

  props: {
    cropperIsVisible: {},
    options: {
      field: 'image',
      width: 200,
      height: 200,
    },
    maxSize: {
      default: 5120,
    },
    maxSizeDisplay: {
      default: 5,
    },
    tooltipText: {},
  },

  data() {
    return {
      isVisible: this.cropperIsVisible,
      imageFormat: 'png',
      langType: 'uk',
      uk: {
        hint: this._('Натисніть, або перетягніть файл в це вікно'),
        loading: this._('Завантажую'),
        noSupported: this._('Ваш браузер не підтримується, будь ласка, використовуйте IE10 + або інші браузери'),
        success: this._('Завантаження виконано успішно'),
        fail: this._('Помилка завантаження'),
        preview: this._('Передперегляд'),
        btn: {
          off: this._('Скасувати'),
          close: this._('Закрити'),
          back: this._('Назад'),
          save: this._('Зберегти'),
        },
        error: {
          onlyImg: `${this._('Допустимі формати зображень -')} .jpg, .jpeg, .png`,
          outOfSize: this._('Зображення перевищує граничний розмір: '),
          lowestPx: this._('Мінімальний розмір зображення: '),
        },
      },
    }
  },

  methods: {
    cropSuccess(data) {
      this.$emit('crop-success', data)
    },

    srcFileSet(fileName, fileType) {
      const [, imgFormat] = fileType.split('/')
      this.imageFormat = imgFormat
    },
  },
}
</script>

import FilterController from '../Controller'

export default {
  props: [
    'value',
    'allProps',
    'additionalProps',
    'pagination',
    'label',
    'filterClass',
    'additionalResource',
    'totalItems',
    'needSearch',
    'portalStatuses',
  ],

  components: {
    FilterController,
  },

  data() {
    return {
      allFilters: this.value.filters,
    }
  },
  methods: {
    handleFilter(parameters) {
      this.$emit('input', parameters)
    },
  },
}

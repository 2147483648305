export const tridentLoader = () => {
  // durty hack for trident(safari (macos ventura)). but its works
  const [trident] = document.getElementsByClassName('trident')

  if (!trident) return

  const { mob, desk } = trident.dataset
  let value = desk
  const windowWidth = 1200
  if (window.innerWidth < windowWidth) {
    value = mob
  }
  trident.style.clipPath = `url(#${value})`
}

<template lang="pug">
  include /mixins
  +b.modal(:class="{'modal--variant_light': meta.isLight}")
    +b.close-popup(
      @click.prevent='$emit("close")'
      :class="{'close-popup--variant_light': meta.isLight}"
    )
      +b.I.icon-close
    +b.P.ds-caption.--size_md.--size_lg-xl.--bold(
      v-if="meta.message.title"
      :class="{'ds-caption--color_white': !meta.isLight, 'ds-caption--color_dark': meta.isLight}"
    ) {{ meta.message.title }}
    +b.ds-panel--space_lg(v-if="meta.message.text")
      +e.element--offset_top
        +b.P.ds-caption.--size_4xs.--size_xs-sm.--size_sm-lg(
          :class="{'ds-caption--color_white': !meta.isLight, 'ds-caption--color_dark': meta.isLight}"
        ) {{ meta.message.text }}
</template>

<script>
export default {
  name: 'UiMessageModal',
  props: ['meta'],
}
</script>

/* eslint-disable */

import { path } from 'ramda'
import { checkAuth } from '@md/auth/permissions'
import { accessMeta } from '@md/permissions'
import { userPermissions } from './index'

const userStateGetter = path(['state', 'users', 'auth'])

export function checkUser(checker, config = {}, ...args) {
  return checkAuth(
    checker, Object.assign({ getter: userStateGetter }, config), ...args
  )
}
export const toHasOwnPermission = permission => checkUser(permission)

export const toHasPermission = permission => toHasOwnPermission(permission)

const announcementRoute = { name: 'profile:announcement:list' }
const settingsRoute = { name: 'profile:settings' }
const initialRoute = userPermissions.includes('ADS:ADS:VIEW') ? announcementRoute : settingsRoute

export const permissionChecker = perms => {
  const arr = perms.reduce((acc, p) => {
    acc.push(userPermissions.includes(p))
    return acc
  }, [])
  return arr.some(el => el)
}

export const accessPermission = (permissions, options) => accessMeta(
  permissionChecker(permissions), initialRoute, options
);

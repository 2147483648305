import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const Display = () => import('./views/Display')

const ROUTE_NAME = 'profile:settings'

export function createRoutes(options) {
  return [
    route('', Display, ROUTE_NAME, {
      meta: accessPermission(['PROFILE:SETTINGS:EDIT'], options),
    }),
  ]
}

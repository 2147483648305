import {
  baseResource,
  createResource,
  postResource,
  optionsResource,
  patchResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'
import { friendlyUrlGenerator } from '@utils/friendly'

const MODEL_PREFIX = 'announcement'

export const ANNOUNCEMENT_CREATE = prefixAPI('/create/', MODEL_PREFIX)
export const ANNOUNCEMENT_RETRIEVE = prefixAPI('/{id}/retrieve-update/', MODEL_PREFIX)
export const ANNOUNCEMENT_DETAIL = prefixAPI('/detail/{id}/', MODEL_PREFIX)
export const ANNOUNCEMENT_LIST = prefixAPI('/list/', MODEL_PREFIX)
export const ANNOUNCEMENT_LIST_RESERVATION = prefixAPI('/list/reservations/', MODEL_PREFIX)
export const ANNOUNCEMENT_GEOMETRY_LIST = prefixAPI('/map-geometry/list/{;filters*}', MODEL_PREFIX)
export const ANNOUNCEMENT_SEARCH = prefixAPI('/search/{?query}', MODEL_PREFIX)
export const ANNOUNCEMENT_MAP_LIST = prefixAPI('/map/list/{;filters*}', MODEL_PREFIX)
export const ANNOUNCEMENT_STATUSES = prefixAPI('/statuses/', MODEL_PREFIX)
export const ANNOUNCEMENT_STATUSE_CHANGE = prefixAPI('/{id}/status-change/', MODEL_PREFIX)
export const ANNOUNCEMENT_STATISTICS = prefixAPI('/statistic/', MODEL_PREFIX)
export const ANNOUNCEMENT_MARKS = prefixAPI('/mark/list/', MODEL_PREFIX)

export const announcementCreateResource = createResource(ANNOUNCEMENT_CREATE, postResource)
export const announcementRetrieveResource = createResource(ANNOUNCEMENT_RETRIEVE)
export const announcementDetailResource = createResource(ANNOUNCEMENT_DETAIL)
export const announcementUpdateResource = createResource(ANNOUNCEMENT_RETRIEVE, patchResource)
export const announcementListResource = friendlyUrlGenerator([
  ANNOUNCEMENT_LIST, '',
], baseResource)
export const announcementListReservationResource = friendlyUrlGenerator([
  ANNOUNCEMENT_LIST_RESERVATION, '',
], baseResource)
export const announcementGeometryListResource = createResource(ANNOUNCEMENT_GEOMETRY_LIST)
export const announcementSearchResource = createResource(ANNOUNCEMENT_SEARCH)
export const announcementMapListResource = createResource(ANNOUNCEMENT_MAP_LIST)
export const announcementOptionsResource = createResource(ANNOUNCEMENT_MAP_LIST, optionsResource)
export const announcementStatusesResource = createResource(ANNOUNCEMENT_STATUSES)
export const announcementStatuseChangeResource = createResource(ANNOUNCEMENT_STATUSE_CHANGE, patchResource)
export const announcementStatisticsResource = createResource(ANNOUNCEMENT_STATISTICS, postResource)
export const announcementMarksResource = createResource(ANNOUNCEMENT_MARKS)

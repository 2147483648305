<template lang="pug">
  include /mixins
  div
    slot(
      :prices="prices"
      :active-price="activePrice"
      :additional-prices="additionalPrices"
    )
      +b.g-row.--appearance_spaced.--space_xs
        +b.g-cell(v-for="item in items")
          +b.i-tag.--size_xs-sm.--variant_1.is-not-interactive(
            :class="{'is-active': item.label === userCurrency}"
          )
            +b.g-row.--space_xs
              +b.g-cell
                +b.SPAN.i-tag__text(v-if="prices[item.key]") {{ prices[item.key]|prettifyPrice }} {{ item.symbol }}
              +b.g-cell(v-if="initialCurrency === item.label")
                +b.SPAN.ds-caption.--color_main *
</template>

<script>
import { getCookie } from '@utils/cookies'
import { prettifyPrice } from '@utils/filters'
import { CURRENCY_SYMBOLS } from '@app/Cabinet/modules/announcement/conts'

const RADIX = 0
const currencyMap = {
  UAH: ['EUR', 'USD'],
  EUR: ['UAH', 'USD'],
  USD: ['UAH', 'EUR'],
}

export default {
  name: 'price-normalizer',

  props: {
    basePrice: {
      default: 0,
    },
    initialCurrency: {
      default: 'UAH',
    },
    initialPrice: {
      default: 0,
    },
  },

  data() {
    return {
      prices: {
        uah: 0,
        usd: 0,
        eur: 0,
      },
      items: [
        {
          label: 'UAH',
          symbol: CURRENCY_SYMBOLS.UAH,
          key: 'uah',
        },
        {
          label: 'USD',
          symbol: CURRENCY_SYMBOLS.USD,
          key: 'usd',
        },
        {
          label: 'EUR',
          symbol: CURRENCY_SYMBOLS.EUR,
          key: 'eur',
        },
      ],
      userCurrency: getCookie('current_currency'),
      activePrice: {},
      additionalPrices: [],
    }
  },

  watch: {
    basePrice: {
      immediate: true,
      handler() {
        this.calcPrices()
        this.setActivePrice()
      },
    },
  },

  methods: {
    multiply(a, b) {
      const val = a * b
      return val.toFixed(RADIX)
    },

    divide(a, b) {
      const val = a / b
      return prettifyPrice(val.toFixed(RADIX))
    },

    getCurrencyTitle(label) {
      return this.currencies.find(el => el.label === label).title
    },

    setActivePrice() {
      this.items.forEach(el => {
        if (el.label === this.userCurrency) {
          const obj = {
            value: prettifyPrice(this.prices[el.key]),
            title: this.getCurrencyTitle(el.label),
            label: el.label,
          }
          this.activePrice = obj
        }
      })
      this.additionalPrices = this.items.filter(o => o.label !== this.userCurrency)
      this.additionalPrices = this.additionalPrices.map(el => {
        const preferedCurrency = el.label === this.initialCurrency ? '*' : ''
        const title = this.getCurrencyTitle(el.label)
        el = `${this.prices[el.key]} ${title} ${preferedCurrency}`
        return el
      })
      this.additionalPrices = this.additionalPrices.join(' / ')
    },

    calcPrices() {
      Object.keys(currencyMap).forEach(c => {
        const newCurrency = c.toLowerCase()
        this.prices[newCurrency] = this.calcToCurrency(
          this.basePrice, newCurrency, this.initialPrice, this.initialCurrency
        )
      })
    },

    calcToCurrency(basePrice, newCurrency, initialPrice, initialCurrency) {
      const rateKey = initialCurrency.toLowerCase()
      if (newCurrency === rateKey) {
        return prettifyPrice(initialPrice.toFixed(RADIX))
      }
      if (!basePrice) {
        basePrice = this.multiply(initialPrice, this.getRate(rateKey))
      }
      if ('uah' === newCurrency) {
        return prettifyPrice(basePrice)
      }
      return this.divide(basePrice, this.getRate(newCurrency))
    },

    getRate(currency) {
      const { rate = 1 } = this.currencyRates[currency] || {}
      return rate
    },
  },
}
</script>

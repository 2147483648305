import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')
const Display = () => import('./views/Display')

const ROUTE_NAME = 'profile:requests:cooperation:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const DISPLAY = p('display')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(['ORDERS:INVEST_REQUESTS:VIEW', 'ORDERS:INVEST_REQUESTS:VIEW_OWN'], options),
    }),
    route(':id/', Display, DISPLAY, {
      props: true,
      meta: accessPermission(['ORDERS:INVEST_REQUESTS:EDIT'], options),
    }),
    { path: '*', redirect: { name: LIST } },
  ]
}

<template lang="pug">
  include /mixins
  div
    +b.section--shadowed.SECTION
      slot(name='title')
      gmap-map#map(
        :center='center',
        :zoom='zoom',
        :options='options'
      )
        gmap-marker(
          :position="markerPosition",
          :clickable="false",
          :icon="markerImage"
        )

</template>
<script>

import {
  mapStyle,
} from '@app/Map/consts.js'

export default {
  name: 'ContactsMap',
  props: ['id', 'lat', 'lng'],
  data() {
    return {
      markerImage: '/static/img/marker.png',
      markerPosition: { lat: Number(this.lat), lng: Number(this.lng) },
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      center: { lat: Number(this.lat), lng: Number(this.lng) },
      zoom: 13,
      options: {
        styles: mapStyle,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
      },
      markers: [],
      infoWinOpen: false,
      infoContent: {},
      categories: [],
    }
  },
  methods: {
    // async getMarkers() {
    //   const { data: { items } } = (await BrandsListResoure.execute())   BrandsListResoure ???
    //   this.markers = items
    //   this.infoContent = this.markers.find(el => el.is_main) || this.markers[0]
    // },
    // async getCategories() {
    //   const { data: { items } } = (await BrandsCategoriesListResoure.execute())
    //   this.categories = items
    // },
    // toggleInfoWindow(marker, idx) {
    //   this.infoWindowPos = marker.position
    //   this.infoContent = marker

    //   if (this.currentMidx === idx) {
    //     this.infoWinOpen = !this.infoWinOpen
    //   } else {
    //     // if different marker set infowindow
    //     // to open and reset current marker index
    //     this.infoWinOpen = true
    //     this.currentMidx = idx
    //   }
    // },
  },

}
</script>

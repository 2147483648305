<template lang="pug">
  include /mixins.pug
  +b.g-row.--appearance_spaced.--align_center
    +b.g-cell(v-if="$slots.labelLeft || labelLeft")
      slot(name="labelLeft")
        +b.SPAN.ds-caption.--size_xs.--color_dark-grey {{ labelLeft }}
    +b.g-cell
      +b.LABEL.ui-switch(:class="[fieldClass, { 'is-disabled': disabled }]")
        +e.INPUT.input(
          type="checkbox"
          :disabled="disabled"
          v-model="formdata.switch"
          @change="emitValue"
        )
        +e.control
    +b.g-cell(v-if="$slots.labelRight || labelRight")
      slot(name="labelRight")
        +b.SPAN.ds-caption.--size_xs.--color_dark-grey {{ labelRight }}
</template>

<script>
export default {
  name: 'UiSwitch',

  props: {
    value: {
      default: false,
    },
    labelLeft: {},
    labelRight: {},
    disabled: {},
    fieldClass: {},
  },

  data() {
    return {
      formdata: {
        switch: this.value,
      },
    }
  },

  watch: {
    value: {
      handler(nval) {
        this.formdata.switch = nval
      },
    },
  },

  methods: {
    emitValue() {
      this.$emit('input', this.formdata.switch)
    },
  },
}
</script>

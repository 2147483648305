<template lang="pug">
  include /mixins
  +b.LABEL.d-control-input__label--static(:for='id')
    +b.g-row.--space_xs.--align_center
      +b.g-cell
        span {{ inputLabel }}
        span(
          v-if='isRequired'
          v-bem.is-required=''
        ) *
      +b.g-cell(v-if="hint")
        +b.hint(v-tooltip.auto="{content: hint, trigger: 'click hover'}")
          +e.icon
            +b.I.icon-info
      +b.g-cell(v-if="target")
        +b.A.ds-link.--variant_1.--flex.is-not-underline(
          target="_blank"
          :href="target"
        )
          +b.I.icon-target
</template>

<script>
export default {
  name: 'StaticLabel',
  block: 'd-control-input',
  props: {
    id: {
      type: String,
      required: false,
    },
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    target: {
      type: String,
    },
  },
}
</script>

import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal'
import { VueAgile } from 'vue-agile'
import Range from 'vue-slider-component'
import * as Tabs from 'vue-slim-tabs'
import 'vue-slim-tabs/themes/default.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueBem from '@aspectus/vue-bem'
import VueGrid from '@aspectus/vue-grid'
import Tag from '@aspectus/vue-tag'
import VueDrawer from '@/js/packages/vue-drawer'
import VueTable from '@/js/packages/vue-table'
import VueDermis from '@aspectus/vue-dermis'
import ClickOutside from 'vue-click-outside'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import { VTooltip, VPopover } from 'v-tooltip'
import Viewer from 'v-viewer'
import ResourceComponents from '@resource'
import VuePermissions from '@aspectus/vue-permissions'
import VueSelectionController from '@aspectus/vue-selection-controller'
import VueAllSelectionController from '@/js/packages/vue-all-selection-controller/src/lib/AllSelectionController'
import Users from '@md/users'
import { ObserveVisibility } from 'vue-observe-visibility'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'

const VueInputMask = require('vue-inputmask').default

export function install(Vue) {
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(VueI18n)
  Vue.use(VueGrid)
  Vue.use(Tag)
  Vue.use(Tabs)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      transition: 'nice-modal-fade',
    },
  })
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(VueInputMask)
  Vue.use(VueDrawer)
  Vue.use(VueTable)
  Vue.use(ResourceComponents)
  Vue.use(VueDermis)
  Vue.use(Viewer)
  Vue.use(VueReCaptcha, {
    siteKey: window.captchaKey,
  })
  Vue.use(VuePermissions)
  Vue.use(Users)
  Vue.use(VueSelectionController)
  Vue.use(VueAwesomeSwiper)
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
  })
  Vue.use(PortalVue)

  Vue.directive('click-outside', ClickOutside)

  Vue.component('VueAllSelectionController', VueAllSelectionController)

  Vue.component('VueAgile', VueAgile)
  Vue.component('range-slider', Range)
  Vue.component('v-popover', VPopover)
  Vue.directive('tooltip', VTooltip)
  Vue.directive('observe-visibility', ObserveVisibility)
}

export default { install }

import {
  createResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'geo'

export const ADMINISTRATIVE_LEVEL_2 = 1420

const GEO_KOATUU_LOCATION_LINK = prefixAPI('/divisions/koatuu-location/', MODEL_PREFIX)
const GEO_DIVISIONS_LINK = prefixAPI('/divisions/hierarchy/{?parent_ids,levels}', MODEL_PREFIX)
const GEO_LAT_LNG_LINK = prefixAPI('/divisions/lat-lon-location/{?lat,lng}', MODEL_PREFIX)

export const geoKoatuuLocationResource = createResource(GEO_KOATUU_LOCATION_LINK, postResource)
export const geoDivisionsResource = createResource(GEO_DIVISIONS_LINK)
export const geoLatLngResource = createResource(GEO_LAT_LNG_LINK, postResource)

import Plugin from './components'

export const userPermissions = JSON.parse(window.USER_PERMISSIONS || '[]')
export const allPermissions = JSON.parse(window.ALL_PERMISSIONS || '[]')

function install(Vue) {
  Vue.use(Plugin)

  Vue.prototype.$userPermissions = userPermissions
  Vue.prototype.$allPermissions = allPermissions
}

export default { install }

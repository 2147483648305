export const playVideo = async () => {
  // video does not loaded before user click to any place on the page
  // if this hack is needed - it works.

  // maybe is redundantly

  let videoIsLoaded = false
  const [video] = document.getElementsByClassName('js-video')

  if (video) {
    const int = setInterval(async () => {
      try {
        await video.play()
        videoIsLoaded = true
      } catch (e) {
        videoIsLoaded = false
      }
      if (videoIsLoaded) {
        clearInterval(int)
      }
    }, 1000)
  }
}

export function createStore() {
  return {
    namespaced: true,

    state: {
      filterIsActive: false,
      query: {},
    },

    mutations: {
      CHANGE_FILTER_STATE(state, result) {
        const xl = 1200
        /* eslint-disable-next-line */
        const filterState = typeof result === 'object' ? result.filterState : result
        const showTrigger = result.showTrigger || false
        if (xl > window.innerWidth || showTrigger) {
          const body = document.querySelector('body')
          if (filterState) {
            body.classList.add('vm--block-scroll')
          } else {
            body.classList.remove('vm--block-scroll')
          }
        }
        state.filterIsActive = filterState
      },

      CHANGE_FILTER_QUERY(state, result) {
        state.query = result
      },
    },
  }
}

<template lang="pug">
  include /mixins
  +b.VALIDATION-OBSERVER(
    @submit.prevent='send',
    ref="validator"
    v-slot="{ valid }",
    tag="form")
    +b.FORM.search-form
      //- +b.A.skip-search(href='/faq')
      //-   +e.cross
      +b.D-CONTROL-INPUT.search-input(v-for='field in fields'
        v-bem:d-control-input="{'variant': 'dark'}"
        :key='field.name'
        :name='field.name'
        :rules='field.rules'
        :type='field.type || "text"'
        :input-label='field.label'
        prepend-icon="icon-search d-control-input__icon--size_xxs"
        v-model='form[field.name]')
      +b.BUTTON.btn--main_xs-padding(type='submit' aria-label='add btn' @click='send()') {{ _('Пошук') }}
</template>

<script>

// import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  faq,
} from '@api/communication.service'

// import {
//   AnswerMessage,
// } from '@utils/submit'

export default {
  name: 'SearchForm',
  props: ['initial'],
  // mixins: [FormMixin],
  data() {
    return {
      fields: [
        {
          name: 'search',
          type: 'search',
          label: this._('Введіть свій пошуковий запит'),
        },
      ],
      form: {
        search: this.initial,
      },
    }
  },
  methods: {
    // updateValidator(errors) {
    //   const parsed = defaultValidatorErrorsParser(errors)
    //   this.non_field_errors = parsed.non_field_errors
    //   this.$refs.validator.setErrors(parsed)
    // },
    // async prepareData(valid) {
    //   this.$refs.validator.validate()
    //   if (!valid) return
    //   this.submit(valid, this.form)
    // },
    send() {
      return faq.execute({}, this.form.search)
    },
  },
}
</script>

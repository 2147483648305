<template lang="pug">
  include /mixins.pug
  +b.g-row.--appearance_spaced.--space_xs.--space_md-xl
    +b.g-cell(v-for="item in languages")
      +b.A.i-tag.--size_xxs.--variant_2(
        :href="getLanguageLink(item.label)"
        :class="{'is-active': item.label === language}"
      )
        +e.SPAN.text {{ item.title }}
    +b.g-cell
      modal-trigger(
        url='Modals/languages/Russian',
        :classes='["vm--modal_2sm vm--modal_variant-1"]'
      )
        template(#default='{ open }')
          +b.i-tag.--size_xxs.--variant_2(@click.prevent="open")
            +e.SPAN.text {{ _("Русский") }}
</template>

<script>
import { CURRENT_LANGUAGE, prefixLanguage } from '@utils/urls'

export default {
  name: 'LanguageSwitch',

  data() {
    return {
      language: CURRENT_LANGUAGE,
      languages: [
        { label: 'uk', title: this._('Українська') },
        // { label: 'ru', title: this._('Русский') },
      ],
    }
  },

  methods: {
    getLanguageLink(language) {
      return prefixLanguage(window.location.href, { language })
    },
  },
}
</script>

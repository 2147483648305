export const largeWord = () => {
  const tags = Array.from(document.getElementsByClassName('tags-block-news-page__tag'))
  tags.forEach(el => {
    el.addEventListener('click', () => {
      el.classList.toggle('tags-block-news-page__tag-active')
    })
  })
  // textTitle.forEach(el => {
  //   const text = el.innerHTML.split(' ')
  //   const firstWord = text.shift()
  //   /* eslint-disable prefer-template */
  //   /* eslint-disable no-return-assign */
  //   return el.innerHTML = `<span class='large-title-intro'>${firstWord + ' '}</span><br class='intro-title-br'>` + text.join(' ')
  // })
}

<script>
export default {
  name: 'UiTabs',
  props: {
    active: {
      default: 0,
    },
  },
  data() {
    return {
      activeItem: this.active,
    }
  },
  methods: {
    activate(item) {
      this.activeItem = item
    },
  },
  render() {
    return this.$scopedSlots.default({
      activeItem: this.activeItem,
      activate: this.activate,
    })
  },
}
</script>

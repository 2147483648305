export const share = () => {
  const newsCardsItem = Array.from(document.getElementsByClassName('news-card'))
  newsCardsItem.forEach(el => {
    const newsText = Array.from(el.querySelectorAll('.news-text'))
    const newsImage = Array.from(el.querySelectorAll('.news-image'))
    newsImage.forEach(im => {
      /* eslint-disable prefer-destructuring */
      const link = im.dataset.link
      newsText.forEach(txt => {
        txt.addEventListener('click', () => {
          window.location.href = link
        })
      })
    })
  })
}

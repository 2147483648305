<template lang="pug">
  include /mixins.pug
  +b.g-container.--variant_tiny
    +b.i-card
      +b.g-row.--apperance_spaced.--justify_center
        +b.g-cell.g-cols.--7-xl
          +b.ds-panel.--space_xl.--space_2xl-xl
            +e.element.--offset_bottom
              +b.P.ds-caption.--color_white.--size_md.--size_xl-xl.--bold {{ _('Заявка на співпрацю') }}
          +b.VALIDATION-OBSERVER(
            @submit.prevent='prepareData',
            ref="validator"
            tag="form"
          )
            +b.g-row.--appearance_spaced.--justify_end.--space_xl
              cell(
                v-for='field in fields'
                :cols='field.cols || "12-xs 6-sm"'
                :key='field.name'
              )
                +b.TAG.d-control-input--variant_dark(
                  autocomplete="off"
                  :tag='field.tag || "d-control-static-input"'
                  :name='field.name'
                  :rules='field.rules'
                  :ref='field.name'
                  :type='field.type || "text"'
                  :input-label='field.label'
                  :mask="field.isMasked ? '+38 (999) 999 99 99' : ''"
                  v-model='formdata[field.name]'
                  :hint="field.hint"
                  :class='{"is-textarea": field.tag }'
                  :rows='field.tag ? "4" : ""'
                )
              +b.g-cell.g-cols.--6-sm.--4-md
                +b.BUTTON.control-button.--rounded(@click.prevent="prepareData")
                  +e.element
                    +e.SPAN.text {{ _('Відправити запит') }}
        +b.g-cell.g-cols.--5-xl
          +b.g-row.--justify_center
            +b.g-cell
              +b.ds-panel.--space_4xl-xl
                +e.element.--offset_top
                  +b.i-avatar.--size_xxl
                    +e.IMG.image(:src="'/static/img/avatar.png'")
            +b.g-cell.g-cols.--12-xs
              +b.ds-panel.--space_2xl
                +e.element.--offset_top
                  +b.P.ds-caption.--appearance_center.--size_2lg.--color_white Дмитрий Верховецкий
              +b.ds-panel.--space_xs
                +e.element.--offset_top
                  +b.P.ds-caption.--appearance_center.--size_md.--color_white Нехай наші експерти виконують роботу за вас
              +b.ds-panel.--space_xl
                +e.element.--offset_top
                +b.g-row.--appearance_spaced.--space_xl.--justify_center
                  +b.g-cell
                    +b.A.i-link.--size_md.--color_white(href="tel:088888888")
                      +e.icon.--variant_2
                        +b.I.icon-phone
                      +e.SPAN.text 0 800 777 777
                  +b.g-cell
                    +b.A.i-link.--size_md.--color_white(href="mailto:info@kupipai.ua")
                      +e.icon.--variant_2
                        +b.I.icon-mail
                      +e.SPAN.text info@kupipai.ua
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
// import { clearPhone } from '@utils/transformers'

export default {
  name: 'AuthCooperation',

  mixins: [FormMixin],

  data() {
    return {
      captchaConfig: {
        isActive: true,
        action: 'staff',
      },
      formdata: {},
      fields: [
        {
          name: 'fio',
          rules: 'required',
          label: this._('ПІБ'),
          tag: 'd-control-static-input',
        },
        {
          name: 'company',
          rules: 'required',
          label: this._('Назва компанії'),
          tag: 'd-control-static-input',
        },
        {
          name: 'tel',
          rules: 'required|phone',
          label: this._('Номер телефону'),
          tag: 'd-control-static-input',
          isMasked: true,
        },
        {
          name: 'email',
          type: 'email',
          rules: 'required|emailMethod',
          label: this._('Ел. пошта'),
          tag: 'd-control-static-input',
          hint: this._('Електронна пошта використовується як вхід при авторизації'),
        },
        {
          name: 'comment',
          label: this._('Введите сообщение'),
          tag: 'd-control-static-textarea',
          cols: '12',
          rules: 'max:1000',
        },
      ],
    }
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()
      if (!isValid) return
      this.submit(isValid, this.formdata)
    },

    // send(data) {
    //   data.phone = clearPhone(data.phone)
    //   return sendJoinUs.execute({}, data).then(() => {
    //     window.dataLayer.push({
    //       event: 'formSuccess',
    //       eventCategory: 'becomeAnHolding',
    //     })
    //   })
    // },
  },
}
</script>

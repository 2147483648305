<template lang="pug">
  include /mixins
  +b.d-control-input.--variant_5
    d-control-static-multiselect(
      :multiple='false',
      :options='choices'
      v-model='checked'
      :name='filterName'
      label='label'
      :input-label='filter.label',
      :placeholder='filter.label',
      @input='emitSelect($event);submit()'
      :maxHeight="224"
    )
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils'

export default {
  name: 'SimpleSelect',

  props: {
    value: {},
    filter: {},
    filterName: {},
  },

  data() {
    return {
      checked: '',
      choices: [
        { label: this._('Все'), value: '' },
        { label: this._('Да'), value: 'true' },
        { label: this._('Нет'), value: 'false' },
      ],
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },

  methods: {
    resetValue() {
      this.checked = ''
    },

    submit() {
      const val = this.normalizeTo(this.checked)

      this.$emit('input', val)
    },

    normalizeTo(value) {
      if (!value) return ''

      const checked = this.choices.find(el => el.value === value.value)

      return checked.value
    },

    normalizeFrom(value) {
      if (isEmpty(value)) return ''

      /* eslint-disable-next-line */
      const checked = this.choices.find(el => el.value === value)

      return checked
    },

    emitSelect(value) {
      this.$emit('select', value)
    },
  },
}
</script>

<template lang="pug">
  include /mixins.pug
  +b.g-container.--variant_tiny
    +b.g-row.--appearance_spaced.--space_xxxl.--align_center
      +b.g-cell.g-cols.--12-xs.--7-xl
        +b.i-card
          +b.ds-panel.--space_xl.--space_3xl-xl
            +e.element.--offset_bottom
              +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_md.--size_2md-md.--size_2lg-xl.--bold {{ _('Bank ID НБУ') }}
          +b.VALIDATION-OBSERVER(
            @submit.prevent='prepareData',
            ref="validator"
            tag="form"
          )
            +b.g-row.--appearance_spaced.--justify_end.--space_xl.--align_end
              +b.g-cell.g-cols.--12-xs.--7-sm.--8-md.--9-xl
                d-control-static-multiselect.d-control-input--variant_dark(
                  :allow-empty="false"
                  :input-label="labels.bank"
                  :placeholder="labels.bank"
                  :options="banks"
                  rules="required"
                  v-model="formdata.bank"
                  label="title",
                )
              +b.g-cell.g-cols.--12-xs.--5-sm.--4-md.--3-xl
                +b.BUTTON.control-button.--rounded(@click.prevent="prepareData")
                  +e.element
                    +e.SPAN.text {{ _('Продовжити') }}
      +b.g-cell.g-cols.--12-xs.--5-xl
        +b.ds-panel.--space_xl.--space_2xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--color_white.--appearance_center-till-xl.--size_md.--size_2md-md.--size_2lg-xl.--lh_2lg-xl {{ _('Як це працює bank id') }}
        +b.P.ds-caption.--color_white.--size_2xs.--size_xs-xl.--lh_default {{ _("Як це працює текст bank id") }}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'AuthBankId',

  mixins: [FormMixin],

  data() {
    return {
      captchaConfig: {
        isActive: true,
        action: 'staff',
      },
      formdata: {},
      labels: {
        bank: this._('Оберіть банк'),
      },
      banks: [
        {
          title: 'Банк 1',
          label: 'bank1',
        },
        {
          title: 'Банк 2',
          label: 'bank2',
        },
        {
          title: 'Банк 3',
          label: 'bank3',
        },
      ],
    }
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()
      if (!isValid) return
      this.submit(isValid, this.formdata)
    },

    // send(data) {
    //   return sendJoinUs.execute({}, data).then(() => {
    //   })
    // },
  },
}
</script>

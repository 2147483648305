/* eslint-disable prefer-object-spread */

import { User } from './user'

export function createStore({ receiveResource, patchResource, UserClass = User }) {
  return {
    namespaced: true,

    state: {
      user: new UserClass({}, false),
      loading: false,
    },

    getters: {
      isDetermined: state => state.user.determined,
    },

    mutations: {
      setUser(state, user) {
        state.user = user
      },

      updateUser(state, data) {
        state.user = new UserClass(Object.assign({}, state.user.info, data))
      },

      setLoading(state, value) {
        state.loading = value
      },
    },

    actions: {
      async initialReceive(store) {
        if (store.state.user.determined) {
          return null
        }

        return store.dispatch('lockedReceive')
      },

      async lockedReceive(store) {
        if (store.state.loading) {
          return null
        }

        return store.dispatch('receive')
      },

      async receive(store) {
        store.commit('setLoading', true)

        return receiveResource.execute().then(res => {
          const { data: { item } } = res

          store.commit('setUser', new UserClass(item))
        }).catch(error => {
          store.commit('setUser', new UserClass({}))

          console.warn(error)
        }).finally(() => store.commit('setLoading', false))
      },

      updateUser(store, payload) {
        return patchResource.execute({}, payload).then(res => {
          const { data: { item } } = res

          store.commit('updateUser', item)
        })
      },
    },
  }
}

import ControlsComponents from './Controls'
import FormsComponents from './Forms'
import MapComponents from './Map'
import TriggersComponents from './Triggers'
import UiComponents from './UiComponents'
import ListComponents from './List'
import FiltersComponents from './Filters'
import PaginationComponents from './Pagination'
import RouterParametersController from './RouterParametersController'
import AuthComponents from './Auth'
import CabinetBaseElements from './Cabinet/components/Base'

export default function plugin(Vue) {
  Vue.use(ControlsComponents)
  Vue.use(FormsComponents)
  Vue.use(MapComponents)
  Vue.use(TriggersComponents)
  Vue.use(UiComponents)
  Vue.use(ListComponents)
  Vue.use(FiltersComponents)
  Vue.use(PaginationComponents)
  Vue.component('RouterParametersController', RouterParametersController)
  Vue.use(AuthComponents)
  Vue.use(CabinetBaseElements)
}

import { CountUp } from 'countup.js'

export const UiCounter = () => {
  const counters = [...document.getElementsByClassName('js-counter-text')]
  if (counters.length) {
    counters.forEach(el => {
      const timeout = Number(el.dataset.delay)
      setTimeout(() => {
        const countUp = new CountUp(el, el.dataset.count, {
          suffix: ` ${el.dataset.suffix}`,
          duration: 2,
          separator: ' ',
        })
        countUp.start()
      }, timeout)
    })
  }
}

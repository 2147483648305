import UiMessageModal from '@app/UiComponents/UiMessageModal'
import UiConfirmModal from '@app/UiComponents/UiConfirmModal'

export const AnswerMessage = (meta, $this, closeEvent) => {
  $this.$modal.show(UiMessageModal, { meta }, {
    adaptive: true,
  }, {
    'before-close': () => {
      if (closeEvent) {
        closeEvent()
      }
    },
  })
}

/**
 * @argument {object} info
 * @argument {object} self
 */
export function openConfirmModal(info, self, modal = UiConfirmModal) {
  return new Promise(resolve => {
    /**
     * @argument {boolean} arg
     */
    const promise = arg => new Promise(res => {
      if (arg) {
        res(arg)
      }
    }).then(result => {
      resolve(result)
    })
    self.$modal.show(modal, {
      info,
      promise,
    }, {
      height: 'auto',
      scrollable: true,
      adaptive: true,
      classes: info.classes,
    })
  })
}

import { timeline } from '@utils/timeline'
import { mapActions, mapState, mapMutations } from 'vuex'
import { queryStringParser } from '@app/Filters/transformers'
import { permissionChecker } from '@md/users/permissions'

import { createI18n } from '@/i18n'
import { createRouter } from './router'
import { createStoreObject } from './store'

export function createApp({ Vue, I18n, Router, Store }) {
  const i18n = createI18n({ Vue, I18n })
  const store = createStoreObject({ Vue, Store })
  const router = createRouter({ Vue, Router, store })

  const ANNOUNCEMENT_ROUTES = [
    'profile:announcement:list',
    'profile:map',
  ]

  Vue.prototype.$body = document.body
  Vue.prototype.currencies = JSON.parse(window.currencies || '[]')
  Vue.prototype.currencyRates = JSON.parse(window.currencyRates || '[]')
  Vue.prototype.taxes = JSON.parse(window.taxes || '{}')

  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],

    data() {
      return {
        fromRouteName: {
          value: null,
        },
      }
    },

    provide() {
      return {
        fromRouteName: this.fromRouteName,
      }
    },

    computed: {
      ...mapState('users/auth', [
        'user',
      ]),
    },

    watch: {
      $route(to, from) {
        this.routeChangeHandler(to, from)
      },
    },

    async created() {
      if (window.isAuthenticated) {
        await this.initialReceive()

        const { TYPES } = this.$allPermissions.PROFILE
        const isOwner = permissionChecker([TYPES.IS_OWNER])

        if (!isOwner) {
          this.openTelegramModal()
        }
      }
    },

    mounted() {
      document.addEventListener('queryChanged', this.queryChangeHandler, true)
    },

    methods: {
      ...mapActions('users/auth', [
        'initialReceive',
      ]),

      ...mapMutations('catalog', [
        'CHANGE_FILTER_QUERY',
      ]),

      queryChangeHandler() {
        if (!ANNOUNCEMENT_ROUTES.includes(this.$route.name)) return

        const search = decodeURIComponent(window.location.search)
        const fromQuery = queryStringParser(search)

        this.CHANGE_FILTER_QUERY(fromQuery)
      },

      routeChangeHandler(to, from) {
        this.fromRouteName.value = from.name

        if (ANNOUNCEMENT_ROUTES.includes(to.name)) {
          this.queryChangeHandler()
        } else {
          this.CHANGE_FILTER_QUERY({})
        }
      },

      checkContent(e) {
        timeline(e)
      },

      print() {
        this.$refs.timelineslider.$refs.slick.goToNext()
      },

      openTelegramModal() {
        const { telegramModalConfirm } = this.user.info.user

        if (telegramModalConfirm) return

        const modal = () => import('@app/Modals/common/Telegram')

        this.$modal.show(modal, {}, {
          classes: ['vm--modal_3sm vm--modal_variant-1'],
        })

        const body = document.querySelector('body')

        body.classList.add('vm--block-scroll')
      },
    },
  })

  return { app, i18n, router, store }
}

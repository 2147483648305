import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')
const Display = () => import('./views/Display')
// const Create = () => import('./views/Create')

const ROUTE_NAME = 'profile:manager:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const CREATE = p('create')
const DISPLAY = p('display')

const permissions = [
  'PROFILE:USERS:VIEW_HOLDING',
  'PROFILE:USERS:VIEW_HOLDING_MANAGER',
  'PROFILE:USERS:VIEW_OWN_HOLDING_MANAGER',
]

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(permissions, options),
    }),
    route('create/', Display, CREATE, {
      meta: accessPermission(permissions, options),
    }),
    route(':id/', Display, DISPLAY, {
      props: true,
      meta: accessPermission(permissions, options),
    }),
    { path: '*', redirect: { name: LIST } },
  ]
}

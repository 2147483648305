<template lang="pug">
  include /mixins
  component(
    ref="slick"
    @init='emmiter'
    @reInit="handleReInit"
    :options="options"
    :class='"default-slider"',
    @after-change='check'
    :is='currentTag'
  )
    slot(
      name='slide',
      :go='e => $refs.slick && $refs.slick.goTo(e)'
    )
    template(#prevButton='')
      slot(name='prev')
        +b.controls--variant_circle.controls-left.mdi.mdi-chevron-left.SPAN
    template(#nextButton='')
      slot(name='next')
        +b.controls--variant_circle.controls-right.mdi.mdi-chevron-right.SPAN

</template>
<script>
import {
  partnersDots,
} from '@utils/mapsDots'

export default {
  name: 'UiSlider',
  props: {
    options: {},
    reiniter: {},
    currentTag: {
      default: 'slick',
    },
    currentRef: {
      type: String,
      default: 'slick',
    },
    hasAdditional: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    reiniter() {
      this.reInit()
    },
  },
  mounted() {
    if (this.reiniter) {
      const time = 1250
      this.reInit()
      setTimeout(() => {
        this.reInit()
      }, time)
    }
    if (this.hasAdditional) partnersDots(0)
  },
  methods: {
    emmiter(event, slick) {
      this.$emit('init', slick)
    },
    check({ currentSlide }) {
      this.$emit('change', currentSlide)
      if (this.hasAdditional) partnersDots(currentSlide)
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$refs.slick.destroy()

      this.$nextTick(() => {
        this.$refs.slick.create()
      })
    },
    handleReInit() {
      // console.log(event, slick)
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.g-row
    template(v-for='val in filter.props.attribute.props.values')
      +b.g-cell.g-cols--12.--narrow-xl
        +b.d-control-input.--appearance_flexible
          +e.element.INPUT(
            type='checkbox'
            :value='val.props.label',
            :name='filter.props.attribute.props.label'
            v-model='checked'
            :input-label='val.props.title',
            :id='`${filter.props.attribute.props.label}:${val.id}`'
            @change='submit()'
            :disabled='!notAvailable(val.id) && filter.is_blocking'
          )
          +e.LABEL.label--static(
            :for='`${filter.props.attribute.props.label}:${val.id}`'
          ) {{ val.props.title }}
</template>
<script>
export default {
  name: 'CheckboxRenderer',
  props: ['value', 'filter', 'available'],
  data() {
    return {
      checked: [],
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },
  methods: {
    submit() {
      this.$emit('input', this.checked)
    },
    notAvailable(id) {
      if (!this.available.attributes) return true
      const customFilter = this.available[this.filter.props.attribute.props.label]
      if (customFilter) {
        return customFilter.find(el => el === id)
      }
      return this.available.attributes.find(el => el === id)
    },
    normalize(value) {
      if (!value) return []
      const checked = this.filter.props.attribute.props.values
        .filter(el => value.find(v => v === el.props.label)).map(el => el.props.label)
      return checked
    },
  },
}
</script>

import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')
const Create = () => import('./views/Create')
const Display = () => import('./views/Display')
const Disabled = () => import('./views/Disabled')
const Published = () => import('./views/Published')
const Moderation = () => import('./views/Moderation')
const Updated = () => import('./views/Updated')

const ROUTE_NAME = 'profile:announcement:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const CREATE = p('create')
const UPDATE = p('update')
const DISPLAY = p('display')
const DISABLED = p('disabled')
const PUBLISHED = p('published')
const MODERATION = p('moderation')
const UPDATED = p('updated')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(['ADS:ADS:VIEW'], options),
    }),
    route('create/', Create, CREATE, {
      meta: accessPermission(['ADS:ADS:ADD'], options),
    }),
    route(':id/update/', Create, UPDATE, {
      props: true,
      meta: accessPermission(['ADS:ADS:EDIT', 'ADS:ADS:EDIT_OWN'], options),
    }),
    route(':id/', Display, DISPLAY, {
      props: true,
      meta: accessPermission(['ADS:ADS:VIEW'], options),
    }),
    route(':id/:identifier/disabled/', Disabled, DISABLED, {
      props: true,
      meta: accessPermission(['ADS:ADS:ADD'], options),
    }),
    route(':id/:identifier/published/', Published, PUBLISHED, {
      props: true,
      meta: accessPermission(['ADS:ADS:ADD'], options),
    }),
    route(':id/:identifier/moderation/', Moderation, MODERATION, {
      props: true,
      meta: accessPermission(['ADS:ADS:ADD'], options),
    }),
    route(':id/:identifier/updated/', Updated, UPDATED, {
      props: true,
      meta: accessPermission(['ADS:ADS:ADD'], options),
    }),
    { path: '*', redirect: { name: LIST } },
  ]
}

<template lang="pug">
  include /mixins
  +b.stats-circle
    +e.head
      +e.P.procent {{ info.value }}#[+e.mini.SPAN %]
      +e.P.desc {{ info.title }}
    +e.body
      figure
        .chart(
          :data-percent='info.value',
          ref='canvas'
        )
</template>
<script>

/* eslint-disable no-multi-assign */
/* eslint-disable camelcase */

const ACCENT_COLOR = '#26BEFD'
const FULL_CIRCLE_COLOR = '#07254F'
const BASE_CIRCLE_COLOR = 'rgba(38,190,253, 0.5)'

const CIRCLE_SIZE = 200
const SHADOW_SIZE = 14

export default {
  name: 'CardPercent',
  props: {
    info: {
      default: () => {},
    },
    base: {},
  },
  data() {
    return {
      hundred: 100,
      one: 1,
      two: 2,
      zero: 0,
      r: 80,
      semiCircle: 180,
      canvas: '',
      ctx: '',
      options: {},
    }
  },
  mounted() {
    this.drawNewGraph()
  },
  methods: {
    createAllVariables() {
      this.options = {
        percent: this.info.value ?
          this.info.value : this.zero,
        size: CIRCLE_SIZE,
        lineWidth: 16,
        rotate: 0,
        color: ACCENT_COLOR,
      }

      this.canvas = document.createElement('canvas')

      this.ctx = this.canvas.getContext('2d')
      this.ctx.clearRect(
        this.zero,
        this.zero,
        this.options.size,
        this.options.size
      )

      this.canvas.width = this.canvas.height = 228
      const el = this.$refs.canvas
      el.innerHTML = ''
      el.appendChild(this.canvas)

      this.ctx.translate(
        this.options.size / this.two,
        this.options.size / this.two
      ) // change center

      this.ctx.rotate(
        (-this.one / this.two + this.options.rotate / this.semiCircle) *
        Math.PI
      ) // rotate -90 deg

      this.r = (this.options.size - this.options.lineWidth) / this.two
    },
    drawCircle(color, percent, hasShadow) {
      if (!percent) return

      percent = Math.min(Math.max(this.zero, percent || this.one), this.one)
      this.ctx.beginPath()

      if (hasShadow) {
        this.ctx.shadowBlur = SHADOW_SIZE
        this.ctx.shadowColor = ACCENT_COLOR
      }
      this.ctx.arc(
        -SHADOW_SIZE,
        SHADOW_SIZE,
        this.r,
        this.zero,
        Math.PI * this.two * percent,
        false
      )
      this.ctx.strokeStyle = color
      this.ctx.lineCap = 'round' // butt, round or square
      this.ctx.lineWidth = this.options.lineWidth
      this.ctx.stroke()
    },
    drawNewGraph() {
      this.createAllVariables()
      this.drawCircle(FULL_CIRCLE_COLOR, this.one)
      if (this.base) {
        this.drawCircle(BASE_CIRCLE_COLOR, this.base.value / this.hundred)
      }
      this.drawCircle(this.options.color, this.options.percent / this.hundred, true)
    },
  },
}
</script>

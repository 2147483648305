<template lang='pug'>
  resource-loader-controller(
    :resource="attributesResource"
    v-slot="allProps"
  )
    resource-loader-controller(
      :resource="additionalResource"
      v-slot="additionalProps"
    )
      component(
        :is="filterVariant === 'default' ? 'DefaultFilter' : 'SearchFilter'"
        :value="filters"
        @input="changeFilters"
        :allProps="allProps",
        :pagination='pagination'
        :label='label',
        :filterClass="filterClass"
        :needSearch="needSearch"
        :additionalProps="additionalProps"
        :additionalResource="additionalResource"
        :totalItems="totalItems"
        :portalStatuses="portalStatuses"
      )
</template>

<script>
import SearchFilter from '@app/Filters'
import DefaultFilter from '@app/Filters/Cabinet'

export default {
  components: { SearchFilter, DefaultFilter },
  props: [
    'filters',
    'pagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterClass',
    'filterVariant',
    'needSearch',
    'totalItems',
    'portalStatuses',
  ],
  methods: {
    changeFilters(value) {
      const params = Object.assign(value, { label: this.label })
      this.$emit('update:filters', params)
    },
  },
}

</script>

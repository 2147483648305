<template lang="pug">
include /mixins
+b.i-tabs
  +e.heading-wrapper
    +e.heading
      +e.item(
        v-for="item in tabs"
        @click.prevent="activate(item.label)"
        :class="{ 'is-active': activeTab === item.label, 'is-error': checkTabError(formErrors, item.validateFields) }"
      )
        +e.SPAN.title {{ item.title }}
  +e.wrapper
    slot(:active-tab="activeTab")
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
    defaultTab: {
      type: String,
    },
    formErrors: {
      type: Object,
    },
  },

  data() {
    return {
      activeTab: this.defaultTab,
    }
  },

  methods: {
    activate(value) {
      this.activeTab = value
    },

    checkTabError(formErrors, tabFields = []) {
      return tabFields.some(key => formErrors[key] && formErrors[key].length)
    },
  },
}
</script>

import installer from '@aspectus/vue-route-installer'
import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const ComponentsInstaller = () => import('./components')
const Display = () => import('./views/Display')

const ROUTE_NAME = 'profile:map'

const permissions = ['ADS:ADS:VIEW']

export function createRoutes(options) {
  const { Vue } = options
  return [
    route('', installer(Display, ComponentsInstaller, Vue), ROUTE_NAME, {
      meta: accessPermission(permissions, options),
    }),
    { path: '*', redirect: { name: ROUTE_NAME } },
  ]
}

/* eslint-disable max-classes-per-file */

export class UserBase {
  determined = false;

  info = {
    user: {},
  };
}

export class User extends UserBase {
  constructor(info, determined = true) {
    super();

    this.determined = determined;
    this.info = info.user ? info : this.info;
  }
}

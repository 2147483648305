export const logoClick = () => {
  /* eslint-disable prefer-destructuring */
  const language = window.language
  const location = ['/', `/${language}/`]

  location.forEach(loc => {
    if (loc === window.location.pathname) {
      const BLOCKED_LINKS = Array.from(document.getElementsByClassName('logo-link'))
      BLOCKED_LINKS.forEach(element => {
        element.classList.add('blocked')
      })
    }
  })
  const BLOCKED_MENU = Array.from(document.getElementsByClassName('js-menu-item'))
  BLOCKED_MENU.forEach(menu => {
    if (window.location.href === menu.href) {
      menu.classList.add('is-blocked')
    }
  })
}

<template lang='pug'>
  include /mixins
  +b.i-filter(:class="{'is-active': filterIsActive}")
    +e.blur(@click.prevent="hideFilter")
    +e.wrapper
      +b.i-preloader.--variant_2(:class="{'is-active': loading}")
        +e.item.--size_md
      +e.heading
        +e.info
          +b.P.ds-caption.--color_dark-grey.--size_2md {{ _('Фильтры') }}
          +b.ds-panel.--space_xs
            +e.element.--offset_top
              +b.P.ds-caption.--color_dark-grey.--size_xs {{ _("Знайдено ділянок:") }} {{ totalCount || totalItems }}

        +e.toggler(@click.prevent="toggleFilter")
          +e.toggler-icon(:class="{'is-active': filterIsActive}")
            +b.I.icon-arrow-right

        +e.toggler.--close(@click.prevent="hideFilter")
          +e.toggler-icon
            +b.I.icon-close

      +e.content
        filter-ui(
          ref="filterUi"
          :disabled="loading"
          :value="prepared"
          :filters="filters"
          :pagination='pagination'
          :additional-resource="additionalResource"
          :needSearch="needSearch"
          :portalStatuses="portalStatuses"
          @input="handleInput"
          @submit:immediate="handleInputFilter(prepared)"
        )
      +e.footer
        +b.g-row.--justify_end.--appearance_spaced
          +b.g-cell.g-cols.--6-xs
          //-   +b.BUTTON.control-button.--offset-horizontal_sm.--transparent(@click.prevent="handleInputFilter(prepared)")
          //-     +e.element
          //-       +e.SPAN.text {{ _('На карті') }}
          //-       +e.icon.--offset_left
          //-         +b.I.icon-marker
          +b.g-cell.g-cols.--6-xs
            +b.BUTTON.control-button.--offset-horizontal_sm(@click.prevent="handleInputFilter(prepared)")
              +e.element
                +e.SPAN.text {{ _('Фільтрувати') }}
          +b.g-cell.g-cols
            +b.i-link.--size_3xs.--color_main.--variant_reset(@click.prevent='handleInputFilter({})')
              +e.icon.--offset_sm
                +b.I.icon-update
              +e.SPAN.text {{ _("Скинути") }}
</template>

<script>

import ControllerMixin from './mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}

</script>

import { createStore as createUsersStore } from '@md/users/store'
import { createStore as createCatalogStore } from '@md/catalog/store'
import { createStore as createCartStore } from '@md/cart/store'

export function createStore(parameters) {
  return {
    modules: {
      users: createUsersStore(parameters),
      catalog: createCatalogStore(parameters),
      cart: createCartStore(parameters),
    },
  };
}

export function createStoreObject({ Store, Vue }) {
  return new Store(createStore({ Store, Vue }));
}

<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced.--align_center
    +b.g-cell.g-cols.--narrow-xs
      +b.SPAN.circle-button.--variant_1.--variant_5(
        @click.prevent="goBack"
      )
        +b.I.icon-left-chevron
    +b.g-cell.g-cols.--auto-xs
      slot
</template>

<script>
export default {
  inject: {
    fromRouteName: {},
  },

  props: {
    fallbackRouteName: {
      type: String,
    },
  },

  methods: {
    goBack() {
      if (this.fromRouteName.value) {
        this.$router.back()
      } else {
        this.$router.push({ name: this.fallbackRouteName })
      }
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced.--align_end
    +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--3-xl.--2-fhd(v-if="needSearch")
      tag(
        :tag="$options.widgetsMap['search']"
        :parameters="parameters"
        :value="value['query']"
        :disabled="disabled"
        @input="updateValue('query', $event)"
      )
    template(v-for="filter in filters")
      +b.g-cell.g-cols.--12-xs.order-10(
        v-if="filter.urlKey === 'geo_path' || filter.urlKey === 'geo_path_short' || filter.urlKey === 'geo_path_region'"
        :class="filter.urlKey === 'geo_path_region' ? 'g-cols--6-lg g-cols--4-fhd' : 'g-cols--8-xl'"
      )
        tag(
          :is-short="filter.urlKey === 'geo_path_short'"
          :is-region="filter.urlKey === 'geo_path_region'"
          :tag="$options.widgetsMap['LocationWidget']"
          :value="preparedValues[filter.urlKey]"
          :ref="filter.urlKey"
          @input="updateValue(filter.urlKey, $event)"
        )
      +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--3-xl.--4-fhd(
        v-else-if="'DateFromToRangeFilter' === filter.type"
      )
        tag(
          :is-wrapped="false"
          :joined-labels="true"
          :tag="$options.widgetsMap[filter.type]"
          :available="available"
          :parameters="parameters"
          :value="preparedValues[filter.urlKey]"
          :filter-name="filter.urlKey"
          :disabled="disabled"
          :filter="filter"
          @input="updateMultipleValues(filter.urlKey, $event)"
        )
      +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--3-xl.--2-fhd(
        v-else
      )
        tag(
          :tag="filter.choiceUrl ? $options.widgetsMap['AutocompleteChoiceFilter'] : $options.widgetsMap[filter.type]"
          :available="available"
          :parameters="parameters"
          :value="preparedValues[filter.urlKey]"
          :filter-name="filter.urlKey"
          :disabled="disabled"
          :filter="filter"
          @input="updateValue(filter.urlKey, $event)"
        )
    slot
</template>

<script>
import UiMixin from '../mixins/UiMixin'

export default {
  mixins: [UiMixin],

  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'needSearch',
  ],
}
</script>

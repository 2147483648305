import UiClickOutside from '@app/UiComponents/UiClickOutside'
import UiShareFlower from '@app/UiComponents/UiShareFlower'
import UiAccordion from '@app/UiComponents/UiAccordion'
import UiAccordionController from '@app/UiComponents/UiAccordionController'
import UiSlider from './UiSlider'
import PercentCard from './PercentCard'
import UiSwitch from './UiSwitch'
import UiUploader from './UiUploader'
import UiTabs from './UiTabs'
import UiTags from './UiTags'
import UiCropper from './UiCropper'

export default function install(Vue) {
  Vue.component('UiSlider', UiSlider)
  Vue.component(UiTags.name, UiTags)
  Vue.component('PercentCard', PercentCard)
  Vue.component('UiSwitch', UiSwitch)
  Vue.component('UiClickOutside', UiClickOutside)
  Vue.component('UiShareFlower', UiShareFlower)
  Vue.component('UiAccordion', UiAccordion)
  Vue.component('UiAccordionController', UiAccordionController)
  Vue.component('UiUploader', UiUploader)
  Vue.component('UiTabs', UiTabs)
  Vue.component('UiCropper', UiCropper)
}

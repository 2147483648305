<template lang="pug">
  include /mixins
  +b.VALIDATION-OBSERVER(
    @submit.prevent='send',
    ref="validator"
    v-slot="{ valid }",
    tag="form")
    +b.form-wrap
      +b.D-CONTROL-INPUT.follow-input(
        v-for='field in fields'
        :key='field.name'
        :name='field.name'
        :rules='field.rules'
        :type='field.type || "text"'
        :input-label='field.label'
        v-model='form[field.name]'
        :placeholder='field.placeholder'
        prepend-icon="icon-mail d-control-input__icon--size_xxs"
      )

      +b.BUTTON.btn--dark.--dark-md(:disabled='isLoad' type='submit' aria-label='add btn' @click.prevent='prepareData(valid)') {{ _('Подписаться') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  subscribe,
} from '@api/communication.service'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'NewsForm',
  mixins: [FormMixin],
  data() {
    return {
      captchaConfig: {
        isActive: true,
        action: 'communication',
      },
      fields: [
        {
          name: 'email',
          type: 'email',
          rules: 'required|emailMethod',
          placeholder: this._('Введите ваш e-mail'),
        },
      ],
      form: {
        email: '',
        source: document.referrer,
        ref: window.location.href,
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.$refs.validator.reset()
      return subscribe.execute({}, data).then(() => {
        const message = {
          message: {
            title: this._('News subscribe form  success title'),
            text: this._('News subscribe form  success text'),
          },
        }
        AnswerMessage(message, this)
        this.form.email = ''
        window.dataLayer.push({
          event: 'formSuccess',
          eventCategory: 'subscribe',
        })
        this.$refs.validator.$el.reset()
        this.$refs.validator.reset()
        this.$emit('close')
      })
    },
  },
}
</script>

export function collectRoutePermissionCheckers(route) {
  return route.matched.reduce((acc, r) => {
    const checker = r.meta?.accessConfig?.checker;

    if (checker) {
      acc.push([checker, r]);
    }

    return acc;
  }, []);
}

export function routeProhibitionResolver(route) {
  const { meta } = route
  if (meta && meta.accessConfig) {
    const { redirect = false } = meta.accessConfig
    return redirect
  }
  return false
}

export function hasRouteAccess(r) {
  const allowedRoutes = collectRoutePermissionCheckers(r)
  if (allowedRoutes.length || !r.name) {
    return true
  }
  return false
}

export const accessGuard = () => (to, from, next) => {
  const timeout = 100

  setTimeout(() => {
    if (hasRouteAccess(to)) {
      next()
    } else {
      const nextRoute = routeProhibitionResolver(to)

      next(nextRoute)
    }
  }, timeout)
}

export const accessConfig = (checker, redirect = false, options = {}) => ({
  checker, redirect, ...options,
});

export function accessMeta() {
  /* eslint-disable prefer-rest-params */
  return { accessConfig: accessConfig.apply(this, arguments) };
}

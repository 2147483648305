<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    announcement: {},
  },

  data() {
    return {
      alreadyAdded: false,
    }
  },

  computed: {
    ...mapState('cart', ['cart']),
  },

  watch: {
    cart: {
      deep: true,
      immediate: true,
      handler() {
        this.check()
      },
    },
    announcement: {
      immediate: true,
      handler() {
        this.check()
      },
    },
  },

  methods: {
    ...mapActions('cart', [
      'updateCartState',
    ]),

    check() {
      if (this.announcement) {
        this.alreadyAdded = Boolean(this.cart.items.find(el => el.announcement === this.announcement))
      }
    },

    async add() {
      const formdata = {
        items: [{
          announcement: this.announcement,
        }],
      }
      const title = {
        success: this._("Об'єкт доданий в кошик"),
        error: this._("Об'єкт не доданий в кошик"),
      }

      await this.$recaptchaLoaded()
      const recaptcha = await this.$recaptcha('cart')
      formdata.recaptcha = recaptcha

      this.updateCartState({ formdata, title, vm: this })
    },
  },

  render() {
    return this.$scopedSlots.default({
      add: this.add,
      alreadyAdded: this.alreadyAdded,
    })
  },
}
</script>

import { renderSlim, resolveInjection } from '@aspectus/vue-utils';

import { defaultName } from './const';

export default {
  name: 'drawer-receiver',

  functional: true,
  props: {
    name: {
      default: defaultName,
    },
  },

  render(h, context) {
    const drawer = resolveInjection(context.props.name, context.parent);
    const nodes = context.scopedSlots.default(drawer && drawer.parameters || {});

    return renderSlim(nodes, h, 'tag');
  },
};

import NewsForm from '@app/Forms/NewsForm'
import SearchForm from '@app/Forms/SearchForm'
import QuestionForm from '@app/Forms/QuestionForm'
import ContactForm from '@app/Forms/ContactForm'
import LandSaleRequest from '@app/Forms/LandSaleRequest'

export default function install(Vue) {
  Vue.component(NewsForm.name, NewsForm)
  Vue.component(SearchForm.name, SearchForm)
  Vue.component(QuestionForm.name, QuestionForm)
  Vue.component(ContactForm.name, ContactForm)
  Vue.component(LandSaleRequest.name, LandSaleRequest)
}

<template lang="pug">
  include /mixins
  div
    +b.checkbox-filter(v-for="item in filter.choices")
      +b.LABEL.d-control-checkbox.--variant_flex.--size_1.--space_none
        +e.element.INPUT(
          type="checkbox"
          :value="item.value",
          :name="filter.urlKey"
          v-model="checked"
          @change="submit()"
        )
        +e.SPAN.label.--variant_bordered.--position_right
        +e.SPAN.content.--type_3xs.--color_dark.--space_right
          +b.g-row
            +b.g-cell
              span {{ item.label }}
            +b.g-cell
              +b.hint(v-tooltip.auto="{content: hints[item.value], trigger: 'click hover'}")
                +e.icon
                  +b.I.icon-info
</template>

<script>
export default {
  props: ['value', 'filter'],

  data() {
    return {
      checked: [],
      hints: {
        1: this._('Moderation hint'),
        2: this._('Processing hint'),
        3: this._('Active hint'),
        4: this._('In work hint'),
        5: this._('Closed hint'),
        6: this._('Duplicate hint'),
      },
    }
  },

  watch: {
    value() {
      this.setInitialValue()
    },
  },

  mounted() {
    this.setInitialValue()
  },

  methods: {
    setInitialValue() {
      if (this.value) {
        this.checked = this.normalize(this.value)
      } else {
        this.checked = []
      }
    },

    resetValue() {
      this.checked = ''
    },

    submit() {
      this.$emit('input', this.checked)
    },

    normalize(value) {
      let val = value

      if (!val) return []

      if (!Array.isArray(val)) {
        val = [val]
      }

      const preparedValue = val.map(el => Number(el))

      const checked = this.filter.choices
        .filter(el => preparedValue.find(v => v === el.value))
        .map(el => el.value)

      return checked
    },
  },
}
</script>

<template lang="pug">
  include /mixins.pug
  +b.g-row.--appearance_spaced.--space_xs.--space_md-xl
    +b.g-cell(v-for="item in currencies")
      +b.i-tag.--size_xxs.--variant_2(
        @click.prevent="setCurrency(item.label)"
        :class="{'is-active': item.label === currentCurrenry}"
      )
        +e.SPAN.text {{ item.title }}
</template>

<script>
import { setCookie, getCookie } from '@utils/cookies'

export default {
  name: 'CurrencySwitch',

  data() {
    return {
      currentCurrenry: null,
    }
  },

  created() {
    this.getCurrency()
  },

  methods: {
    getCurrency() {
      const currency = getCookie('current_currency')
      if (currency) {
        this.currentCurrenry = currency
      } else {
        setCookie('current_currency', 'UAH')
        this.currentCurrenry = 'UAH'
      }
    },

    setCurrency(label) {
      setCookie('current_currency', label)
      window.location.reload()
    },
  },
}
</script>

<template lang='pug'>
  router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initialParams"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    :get-data="getData"
    :need-update-url-on-mounted="needUpdateUrlOnMounted"
    v-slot="{ parameters, changeParameters }"
    @update:parameters="$emit('update:parameters', $event)"
  )
    paginated-resource-controller(
      :resource="listResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      v-slot="paginatedProps"
      @result="$emit('result', $event)"
    )
      component(
        ref="controller"
        :is="componentName"
        :attributesResource='filtersResource'
        :additionalResource='additionalResource'
        v-bind="paginatedProps",
        :totalItems="(paginatedProps.result && paginatedProps.result.items) ? paginatedProps.result.items.length : 0"
        :label='label',
        :filterClass="filterClass"
        :filterVariant="filterVariant"
        :needSearch="needSearch"
        :portalStatuses="portalStatuses"
      )
        template(#heading)
          slot(name="heading")
        slot(
          :items="paginatedProps.result ? paginatedProps.result.items : []"
          :total="paginatedProps.pagination.total"
        )
</template>

<script>
import Controller from '@app/List/Controller'
import ControllerCabinet from '@app/List/ControllerCabinet'
import { queryStringParser, parsePaginationUrl } from '@app/Filters/transformers'

export default {
  components: { Controller, ControllerCabinet },
  props: {
    initial: {},
    baseUrl: {},
    listResource: {},
    filtersResource: {},
    additionalResource: {},
    label: {},
    componentName: {},
    filterClass: {},
    filterVariant: {},
    needSearch: {},
    needUpdateUrlOnMounted: {},
    portalStatuses: {},
  },

  data() {
    return {
      initialParams: {},
    }
  },

  created() {
    const query = parsePaginationUrl()

    this.initialParams = {
      ...this.initial,
      ...query,
    }
  },

  methods: {
    getData(orderParams) {
      if (this.$refs.controller && this.$refs.controller.getData) {
        this.$refs.controller.getData(orderParams)
      }
    },

    getDataWithoutPagination(params) {
      if (this.$refs.controller && this.$refs.controller.handlePaginate) {
        this.$refs.controller.handlePaginate(params)
      }
    },

    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        label: this.label,
      }
    },

    parametersFrom({ ...rest }) {
      const search = decodeURIComponent(window.location.search)
      const data = queryStringParser(search)
      const query = parsePaginationUrl()
      const pagination = {
        limit: query.limit ? query.limit : (this.initial?.limit || null),
        offset: query.offset || 0,
      }

      return {
        ...rest,
        filters: data,
        ...pagination,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>

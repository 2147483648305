<template lang="pug">
  include /mixins
  +b.question-form-block
    +e.H1.title {{ _('Якщо ви не знайшли своє запитання,заповніть форму') }}
    +b.VALIDATION-OBSERVER(
      @submit.prevent='send',
      ref="validator"
      v-slot="{ valid }",
      tag="form")
      row(
        appearance='spaced'
        space='xl'
      )
        cell(
          v-for='field in fields'
          :cols='field.cols'
          :key='field.name'
        )
          +b.TAG(
            v-bem:d-control-input="{'variant': 'dark'}"
            :tag='field.tag || "d-control-input"'
            :name='field.name'
            :rules='field.rules'
            :ref='field.name'
            :type='field.type || "text"'
            :input-label='field.label'
            :class='{"is-textarea": field.tag }'
            v-model='form[field.name]'
            v-bind='[field.props]'
            rows='8'
          )
        +b.g-cell.g-cols--12.--9-xl
        +b.g-cell.g-cols--12.--3-xl
          +b.BUTTON.btn--main_xs-padding(:disabled='isLoad' type='submit' aria-label='add btn' @click.prevent='prepareData(valid)') {{ _('Запитати') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { contact } from '@api/communication.service'
import { AnswerMessage } from '@utils/submit'
import { masking } from '@utils/helpers'
import { clearPhone } from '@utils/transformers'

export default {
  name: 'QuestionForm',
  mixins: [FormMixin],
  props: ['questions'],
  data() {
    return {
      captchaConfig: {
        isActive: true,
        action: 'communication',
      },
      fields: [
        {
          name: 'fullName',
          rules: 'required',
          label: this._('Ваше імя'),
          cols: '12 6-xl',
        },
        {
          name: 'tel',
          rules: 'required|phone',
          label: this._('Номер телефону'),
          cols: '12 6-xl',
        },
        {
          name: 'email',
          type: 'email',
          rules: 'required|emailMethod',
          label: this._('Ел. пошта'),
          cols: '12 6-xl',
        },
        {
          name: 'topic',
          label: this._('Тема'),
          cols: '12 6-xl',
          rules: 'required',
          props: {
            options: this.questions,
            label: 'topic',
          },
          tag: 'd-control-multiselect',
        },
        {
          type: 'textarea',
          name: 'message',
          rules: 'required',
          label: this._('Розкажіть про свою проблему. Чим більше деталей, тим краще'),
          tag: 'd-control-textarea',
          cols: '12',
        },
      ],
      form: {
        fullName: '',
        tel: '',
        email: '',
        topic: '',
        message: '',
        source: document.referrer,
        ref: window.location.href,
      },
    }
  },
  async mounted() {
    masking('tel', this)
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      const data = JSON.parse(JSON.stringify(this.form))
      data.topic = this.form.topic.id
      this.submit(valid, data)
    },
    send(data) {
      this.$refs.validator.reset()
      data.tel = clearPhone(data.tel)
      return contact.execute({}, data).then(() => {
        const message = {
          message: {
            title: this._('Question form  success title'),
            text: this._('Question form  success text'),
          },
        }
        AnswerMessage(message, this)
        window.dataLayer.push({
          event: 'formSuccess',
          eventCategory: 'question',
        })
        this.fullName = ''
        this.tel = ''
        this.email = ''
        this.topic = ''
        this.message = ''

        this.$refs.validator.$el.reset()
        this.$refs.validator.reset()
        this.$emit('close')
      })
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  div(:class="{ 'i-panel i-panel--default': isWrapped }")
    +b.ds-panel.--space_sm(v-if="!joinedLabels")
      +e.element.--offset_bottom
        +b.g-row.--justify_between
          +b.g-cell
            +b.P.ds-caption.--size_3xs.--color_dark-grey {{ filter.label }}
    +b.g-row.--appearance_spaced
      +b.g-cell.g-cols.--6-xs
        d-control-static-datepicker(
          input-class="d-control-input--variant_5"
          v-model='checked[0]',
          autocomplete="off"
          name='startDate'
          :config="{}"
          :input-label='labels.startDate'
          @on-change="debounceFn"
        )
      +b.g-cell.g-cols.--6-xs
        d-control-static-datepicker(
          input-class="d-control-input--variant_5"
          v-model='checked[1]',
          autocomplete="off"
          name='endDate'
          :config="{}"
          :input-label='labels.endDate'
          @on-change="debounceFn"
        )
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'DateRangeWidget',

  props: {
    value: {},
    filter: {},
    isWrapped: {
      type: Boolean,
      default: true,
    },
    joinedLabels: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checked: [null, null],
      debounceFn: () => {},
      labels: {
        startDate: this.joinedLabels ? `${this.filter.label} ${this._('с')}` : this._('с'),
        endDate: this.joinedLabels ? `${this.filter.label} ${this._('по')}` : this._('по'),
      },
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nval) {
        this.setValue(nval)
      },
    },
  },

  mounted() {
    const timeout = 100
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.debounceFn = debounce(this.submit, timeout)
  },

  methods: {
    setValue(val) {
      let value = val
      if (!val) {
        value = []
      }

      this.$set(this, 'checked', value)
    },

    submit() {
      const isEmpty = this.checked.every(el => !el)
      if (isEmpty) {
        return
      }
      this.$nextTick(() => {
        const val = this.checked
        this.$emit('input', val)
      })
    },
  },
}
</script>

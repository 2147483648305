<template lang="pug">
  include /mixins
  d-control-static-multiselect(
    input-class="d-control-input--variant_5"
    v-model="checked"
    :label="optionLabel"
    :input-label="filter.label"
    :placeholder="showPlaceholder ? filter.label : ''"
    :searchable="true"
    :multiple="multipleAutocomplete"
    :internal-search="false"
    :options="localOptions"
    :show-no-results="true"
    track-by="id"
    @search-change="search"
    v-bind="$attrs"
    :rules="rules"
    @input="submit"
  )
    template(v-slot:noResult) {{ _('No elements found. Consider changing the search query.') }}
    template(v-slot:noOptions) {{ _('Type to search.') }}
    template(v-slot:afterList)
      div(
        v-if="hasNextPage"
        v-observe-visibility="{ callback: setVisibility }"
      )
    template(
      slot="selection"
      slot-scope="{ values, search }"
      v-if="values.length"
    )
      +b.SPAN.multiselect__single
        | {{ values.map(el => el[optionLabel]).join(', ') }}
      +b.i-count.--variant_absolute
        +e.text {{ values.length }}
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils'

export default {
  name: 'filter-autocomplete-multiselect-widget-component',
  props: {
    value: {},
    filter: {},
    receive: {},
    placeholder: {},
    options: {},
    parameters: {},
    result: {},
    rules: {},
    showPlaceholder: {
      default: true,
    },
    initialReceive: {
      default: true,
    },
    emitFullValue: {
      default: false,
    },
    multipleAutocomplete: {
      default: false,
    },
  },

  data() {
    return {
      paginationIsDisabled: false,
      isLoading: false,
      isPagination: false,
      localOptions: [],
      query: null,
      checked: this.multipleAutocomplete ? [] : '',
      isMounted: true,
      additionalPaginate: false,
      optionLabel: this.filter.optionLabel || 'title',
    }
  },

  created() {
    if (this.initialReceive) {
      this.receive()
    }
  },

  computed: {
    hasNextPage() {
      const page = this.result?.pagination?.nextPage

      return Boolean(page)
    },
  },

  watch: {
    result: {
      handler(nval) {
        this.setOptions(nval)
      },
    },

    value: {
      immediate: true,
      handler(nval) {
        this.setInitialValue(nval)
      },
    },
  },

  methods: {
    resetValue() {
      if (this.multipleAutocomplete) {
        this.value = []
      } else {
        this.value = ''
      }
    },

    setInitialValue(nval) {
      this.checked = this.normalizeFrom(nval)
    },

    submit() {
      this.$nextTick(() => {
        if (this.emitFullValue) {
          this.$emit('input', this.checked)
        } else {
          const val = this.normalizeTo(this.checked)

          this.$emit('input', val)
        }
      })
    },

    normalizeTo(value) {
      if (!value) return []

      if (Array.isArray(value)) {
        return value.map(el => el.id)
      }

      return value.id
    },

    normalizeFrom(value) {
      if (isEmpty(value)) {
        return this.multipleAutocomplete ? [] : ''
      }

      if (Array.isArray(value)) {
        /* eslint-disable-next-line */
        let checked = this.localOptions.filter(el => {
          const option = value.find(v => (v).toString() === (el.id).toString())

          if (option) {
            return el
          }

          return false
        })

        return checked
      }

      const valueToFind = value?.id || value

      /* eslint-disable-next-line */
      let checked = this.localOptions.find(el => el.id == valueToFind)

      if (!checked && this.filter.value && this.filter.title) {
        checked = {
          value: this.filter.value,
          [this.optionLabel]: this.filter.title,
        }

        this.localOptions.push(checked)
      }

      return checked
    },

    setOptions(nval) {
      const items = nval?.items

      if (this.isPagination) {
        if (this.filter.value && this.filter.value === this.value) {
          const option = items.find(el => el.id === this.filter.value)

          if (option) {
            this.localOptions = this.localOptions.filter(el => el.value !== this.filter.value)

            this.filter.value = null

            this.localOptions.push(...items)

            this.checked = this.normalizeFrom(this.value)
          }
        } else {
          this.localOptions.push(...items)
        }
      } else {
        this.localOptions = items || []
      }

      if (this.additionalPaginate) {
        this.setInitialValue(this.value)

        this.additionalPaginate = false
      }

      if (this.isMounted) {
        this.setInitialValue(this.value)

        if (this.value && !this.checked && this.hasNextPage) {
          this.additionalPaginate = true

          this.paginate()
        }

        this.isMounted = false
      }
    },

    search(query) {
      this.isPagination = false

      this.query = query

      this.receive({ query })
    },

    setVisibility(reached) {
      if (reached) {
        this.paginate()
      }
    },

    paginate() {
      const { nextPage: page } = this.result.pagination

      this.isPagination = true

      this.receive({ page, query: this.query })
    },
  },
}

</script>

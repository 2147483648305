export const STATUS_COLORS = {
  1: 'is-moderate',
  2: 'is-process',
  3: 'is-active',
  4: 'is-work',
  5: 'is-closed',
  6: 'is-duplicated',
  100: 'is-work-own',
  101: 'is-bought',
}

export const CURRENCY_SYMBOLS = {
  UAH: '₴',
  USD: '$',
  EUR: '€',
}

export const ACTIVE_STATUSES = [3, 4]

<template lang='pug'>
  include /mixins.pug
  +b.ui-uploader
    +e.content
      +e.wrapper(:class="{'is-disabled': files.length == maxFiles}")
        +b.g-row.--space_xl.--align_center
          +b.g-cell.g-cols
            label
              slot(name='button')
              input.is-disable(
                ref="inputFile"
                type='file'
                :id='inputId'
                :name='inputId'
                :multiple='multipleFiles'
                :accept='acceptedFilesLocal'
                @change='onFileChange'
              )
          +b.g-cell.g-cols.--auto-sm
            +b.P.ds-caption.--color_dark-grey.--size_3xs.--lh_default {{ _("Вимоги до завантажуваних файлів") }}
            +b.P.ds-caption.--color_dark-grey.--size_3xs.--lh_default {{ `(${_("Максимальна кількість файлів -")} ${maxFiles}, ${_("максимальний розмір файлу -")} ${maxSize} ${_("Мб.")}, ${_("дозволені формати -")} ${acceptedFiles})` }}
              span(v-if="minResolution") , {{ _("минимально рекомендуемое разрешение") }} {{ minResolution }}
    +e.errors
      slot(name='errors' :fileErorrs='fileErorrs')
        +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default(v-if='fileErorrs.limit') {{ _("Перевищено максимальну кількість файлів для завантаження") }}
        +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default(v-if='fileErorrs.size') {{ _("Перевищена максимальна вага файла для завантаження") }}
        +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default(v-if='fileErorrs.type') {{ _("Не підтримуваний формат файлу") }}
        +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default(v-if='fileErorrs.height') {{ _("Максимальна висота зображення - ") }} {{ maxHeight }}px
        +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default(v-if='fileErorrs.width') {{ _("Максимальна ширина зображення - ") }} {{ maxWidth }}px
      slot(name='required')
    +e.list
      +b.ds-panel.--space_xl.--space_4xl-xl(v-if='files.length > 0')
        +e.element.--offset_top
          +b.g-row.--space_sm.--space_xl-sm.--appearance_spaced
            +b.g-cell.g-cols.--4-xs.--3-sm(
              v-for='(file, index) in files'
              v-if="file && !file['_delete']"
            )
              +b.ui-uploader-item(:class="{'ui-uploader-item--variant_1': !isImageFile(file.src)}")
                template(v-if='isImageFile(file.src)')
                  +b.ds-aspect-ratio.--appearance_filled.--ratio_4x3
                    +e.body
                      +b.image-wrapper
                        +e.IMG.item(:src='file.src')
                  validation-provider(
                    tag="div"
                    v-slot="{errors}"
                    :name="'gallary_image_' + index"
                  )
                    +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default(v-if="errors[0]") {{ errors[0] }}
                +e.tag(v-else)
                  +e.tag-icon-wrapper
                    +e.IMG.tag-icon(:src="'/static/img/file.svg'")
                  +e.SPAN.tag-name {{ files[index].name }}
                +e.remove(
                  @click='removeFile(index)'
                  :class="{'ui-uploader-item__remove--variant_1': !isImageFile(file.src)}"
                )
                  slot(name='remove')
                    i x
</template>
<script>
/* eslint-disable */

const imageTypes = [
  'data:image/jpeg;base64',
  'data:image/jpg;base64',
  'data:image/png;base64',
  'data:image/gif;base64',
  '.jpeg',
  '.jpe',
  '.jpg',
  '.png',
  '.gif',
]

export default {
  name: 'UiUploader',

  props: {
    inputId: {
      type: String,
      default: 'upload',
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
    acceptedFiles: {
      type: String,
      default: '.jpg, .jpeg, .png',
    },
    multipleFiles: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: 'Upload file',
    },
    defaultFiles: {
      type: Array,
      default: () => [],
    },
    minResolution: {},
    maxWidth: {
      default: 4000,
    },
    maxHeight: {
      default: 4000,
    },
  },

  data() {
    return {
      acceptedFilesLocal: '',
      bytes: 1000,
      megabyte: 1048576,
      files: [],
      zero: 0,
      one: 1,
      fileErorrs: {
        limit: false,
        size: false,
        type: false,
        width: false,
        height: false,
      },
      defaultFilesIsSetted: false,
    }
  },

  watch: {
    defaultFiles: {
      handler() {
        if (!this.defaultFilesIsSetted) {
          this.setDefaultFiles()
        }
      },
    },
  },

  mounted() {
    this.acceptedFilesLocal = this.acceptedFiles.replace(/ /g, '')
    this.setDefaultFiles()
  },

  methods: {
    setDefaultFiles() {
      if (this.defaultFiles && this.zero < this.defaultFiles.length) {
        this.files.push(...this.defaultFiles)
        this.defaultFilesIsSetted = true
      }
    },

    isImageFile(src) {
      const lowercaseUrl = src.toLowerCase()
      const imageTypeIsIncluded = imageTypes.some(el => lowercaseUrl.includes(el))
      return imageTypeIsIncluded
    },

    onFileChange(e) {
      this.defaultFilesIsSetted = true
      this.fileErorrs.size = false
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createFile(files)
    },

    emitFiles() {
      const timeout = 100
      setTimeout(() => {
        this.$emit('input', this.files)
        this.$refs.inputFile.value = ''
      }, timeout)
    },

    async createFile(files) {
      await this.validateFile(files).then(() => {
        const timeout = 300
        setTimeout(() => {
          const errorsIsClean = this.checkErrors()
          if (!errorsIsClean) return
          Object.keys(files).forEach(el => {
            const reader = new FileReader()
            const fileSize = (files[el].size / this.bytes).toFixed(this.zero)
            reader.onload = e => {
              const fileObj = {
                src: e.target.result,
                name: files[el].name,
                size: fileSize,
                isUploaded: true,
              }
              this.files.push(fileObj)
            }
            reader.readAsDataURL(files[el])
            this.emitFiles()
          })
        }, timeout)
      })
    },

    checkErrors() {
      return Object.values(this.fileErorrs).every(el => !el)
    },

    async validateFile(files) {
      this.validateFileFormat(files)
      this.validateFilesLength(files)
      this.validateFileSize(files)
      await this.validateResolution(files)
    },

    async validateResolution(files) {
      return new Promise((resolve, reject) => {
        // Clear resolution errors
        this.fileErorrs.width = false
        this.fileErorrs.height = false
        Object.keys(files).forEach((el, index) => {
          const reader = new FileReader()
          reader.readAsDataURL(files[el])
          reader.onload = e => {
            // Resolve promise if it isn't image file
            if (!this.isImageFile(e.target.result)) {
              resolve()
            }
            // Create new image for getting it height and width
            let img = new Image()
            img.src = e.target.result
            img.onload = () => {
              // Check height of image
              if (this.maxWidth < img.width) {
                this.fileErorrs.width = true
                reject()
              }
              // Check height of image
              if (this.maxHeight < img.height) {
                this.fileErorrs.height = true
                reject()
              }
              const one = 1
              if (index === files.length - one) {
                resolve()
              }
            }
          }
        })
      })
    },

    validateFileFormat(files) {
      const acceptedFormats = this.acceptedFilesLocal.split(',')
      // Get file format
      let fileFormat = files[0].name.split('.')
      this.fileErorrs.type = false
      fileFormat = `.${fileFormat[fileFormat.length - this.one]}`
      // Check if files includes accepted format
      if (this.zero > acceptedFormats.indexOf(fileFormat.toLowerCase())) {
        this.fileErorrs.type = true
      }
    },

    validateFilesLength(files) {
      this.fileErorrs.limit = false
      // Get not deleted files for limit validation
      const isNotDeletedItems = this.files.reduce((acc, el) => {
        if (el && !el['_delete']) acc++
        return acc
      }, 0)
      // Check if files limit is valid
      if (isNotDeletedItems + files.length > parseInt(this.maxFiles)) {
        this.fileErorrs.limit = true
      }
    },

    validateFileSize(files) {
      // Check if files size is valid
      this.fileErorrs.size = Object.keys(files).some(el => {
        return files[el].size > parseInt(this.maxSize) * this.megabyte
      })
    },

    removeFile(index) {
      // Get file for delete
      const file = this.files[index]
      if (file && file.id) {
        // Add delete key for images from server
        file['_delete'] = true
      } else {
        // Remove deleted file if it's not server file
        this.files.splice(index, this.one)
      }
      this.emitFiles()
    },

    resetFiles() {
      this.files = []
    },

    setMainItem(index) {
      this.$nextTick(() => {
        this.files.forEach(el => {
          el.isMain = false
        })
        this.files[index].isMain = true
      })
    },
  },
}
</script>

<script>
export default {
  name: 'UiShareFlower',
  data() {
    return {
      show: false,
    }
  },
  methods: {
    close() {
      this.show = false
    },
    toggle() {
      this.show = !this.show
    },
  },
  render() {
    return this.$scopedSlots.default({
      show: this.show,
      close: () => this.close(),
      toggle: () => this.toggle(),
    })
  },

}
</script>

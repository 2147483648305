<template lang="pug">
  include /mixins.pug
  +b.i-card
    // +b.ds-panel.--space_xl.--space_2xl-xl
    //   +e.element.--offset_bottom
    //     +b.g-row.--appearance_spaced.--justify_center.--justify_between-md.--align_center
    //       +b.g-cell.g-cols.--5-md
    //         +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_md.--size_2md-md.--size_2lg-xl.--lh_2lg-xl
    //           +b.A.ds-link.--size_md.--size_2md-md.--size_2lg-xl.--color_main.--inline(:href="isProduction ? '' : actualBankIdUrl") {{ _('Швидка реєстрація') }}
    //           |&nbsp;{{ _('за допомогою BankID НБУ') }}
    //       +b.g-cell.is-disable.is-visible-md
    //         +b.ds-inliner
    //           +e.body
    //             +b.ds-caption.--size_md.--color_main
    //               +b.I.icon-arrow-right
    //       +b.g-cell.g-cols.--5-md
    //         +b.g-row.--appearance_spaced.--justify_center.--justify_end-md.--align_center
    //           +b.g-cell.g-cols.--8-xs.--9-sm
    //             a(:href="isProduction ? '' : actualBankIdUrl")
    //               img.pointer(:src="'/static/img/BankID.svg'")
    // +b.i-divider
    //   +e.content
    //     +e.SPAN.text {{ _("або") }}
    +b.ds-panel.--space_xl.--space_3xl-xl
      +e.element.--offset_bottom
        +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_md.--size_2md-md.--size_2lg-xl {{ _('Зареєструйтесь за допомогою Email') }}
    +b.VALIDATION-OBSERVER(
      @submit.prevent='prepareData',
      ref="validator"
      tag="form"
    )
      +b.g-row.--appearance_spaced.--justify_end.--space_xl
        cell(
          v-for='field in fields'
          :cols='field.cols || "12-xs 6-sm"'
          :key='field.name'
        )
          +b.TAG.d-control-input--variant_dark(
            autocomplete="off"
            :tag='field.tag || "d-control-static-input"'
            :name='field.name'
            :rules='field.rules'
            :ref='field.name'
            :type='field.type || "text"'
            :input-label='field.label'
            :hint="field.hint"
            :mask="field.isMasked ? '+38 (999) 999 99 99' : ''"
            v-model='formdata[field.name]'
          )
        +b.g-cell.g-cols.--12-xs.--6-sm
          d-control-static-input(
            autocomplete="off"
            input-class="d-control-input--variant_dark"
            name="rnokpp"
            type="number"
            :hint="_('Реєстраційний номер облікової картки платника податків')"
            :input-label="labels.rnokpp"
            v-model="formdata.rnokpp"
            :rules="{required: true, numeric: true, edrpou_individual: true}"
          )
        +b.g-cell.g-cols.--12-xs.--6-sm
          d-control-static-input(
            autocomplete="off"
            input-class="d-control-input--variant_dark"
            name="password"
            type="password"
            :input-label="labels.password"
            v-model="formdata.password"
            rules="required|max:32|min:8"
          )
          +b.ds-panel.--space_sm
            +e.element.--offset_top
              +b.P.ds-caption.--color_white.--size_4xs.--lh_default {{ _('Пароль повинен складатися з не менш ніж 6 символів, містити цифри та латинські літери, у тому числі великі') }}
      +b.ds-panel.--space_xl.--space_3xl-xl
        +e.element.--offset_top
          validation-provider(
            slim
            v-slot="{errors}"
            name="rules"
            :rules="{required: { allowFalse: false }}"
          )
            +b.LABEL.d-control-checkbox.--variant_flex
              +b.error-label(v-if="errors[0]") {{ errors[0] }}
              +e.INPUT.element(
                type='checkbox'
                v-model='formdata.rules'
              )
              +e.SPAN.label
              +e.SPAN.content
                +e.SPAN.title
                  span {{ _('Погоджуюсь з') }} &nbsp;
                  +b.A.ds-link.--size_sm.--color_main.--inline(
                    :href="policyUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  ) {{ _('політикою конфіденційності та правилами сайту') }}
      +b.ds-panel.--space_xl
        +e.element.--offset_top
          validation-provider(
            slim
            v-slot="{errors}"
            name="agreementAcceptance"
            :rules="{required: { allowFalse: false }}"
          )
            +b.LABEL.d-control-checkbox.--variant_flex
              +b.error-label(v-if="errors[0]") {{ errors[0] }}
              +e.INPUT.element(
                type='checkbox'
                v-model='formdata.agreementAcceptance'
              )
              +e.SPAN.label
              +e.SPAN.content
                +e.SPAN.title
                  span {{ _('Ознайомлений з умовами') }} &nbsp;
                  +b.A.ds-link.--size_sm.--color_main.--inline(
                    :href="publicContractUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  ) {{ _('публічного договору про надання послуг') }}&nbsp;
                  span {{ _('та з умовами') }} &nbsp;
                  +b.A.ds-link.--size_sm.--color_main.--inline(
                    :href="secondPublicContractUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  ) {{ _('публічного договору про надання послуг для ФОП') }}
      validation-provider(
        slim
        v-slot="{errors}"
        name="nonFieldErrors"
      )
        +b.ds-panel.--space_xl.--space_3xl-xl(v-if="errors[0]")
          +e.element.--offset_top
            +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default {{ errors[0] }}
      +b.ds-panel.--space_xl.--space_3xl-xl
        +e.element.--offset_top
          +b.g-row.--appearance_spaced.--justify_center.--justify_end-sm.--space_xl.--align_center
            +b.g-cell
              +b.A.ds-link.--color_white.--size_sm.--bold(:href="authUrl") {{ _('Я вже зареєстрований') }}
            +b.g-cell.g-cols.--12-xs.--6-sm
              +b.BUTTON.control-button.--rounded(@click.prevent="prepareData")
                +e.element
                  +e.SPAN.text {{ _('Зареєструватися') }}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { registerInvestor } from '@api/auth.service'
import { prefixLanguage } from '@utils/urls'
import { clearPhone } from '@utils/transformers'

export default {
  props: {
    authUrl: {},
    bankidUrl: {},
    policyUrl: {},
  },

  mixins: [FormMixin],

  data() {
    return {
      captchaConfig: {
        isActive: true,
        action: 'staff',
      },
      publicContractUrl: window.publicContractUrl,
      secondPublicContractUrl: window.secondPublicContractUrl,
      // isProduction: 'https://kupypai.com.ua' === window.location.origin,
      formdata: {
        type: 'investor',
        agreementAcceptance: false,
      },
      fields: [
        {
          name: 'firstName',
          rules: 'required',
          label: this._('Имя'),
        },
        {
          name: 'lastName',
          rules: 'required',
          label: this._('Прізвище'),
        },
        {
          name: 'phone',
          rules: 'required|phone',
          label: this._('Номер телефону'),
          isMasked: true,
        },
        {
          name: 'email',
          type: 'email',
          rules: 'required|emailMethod',
          label: this._('Ел. пошта'),
          hint: this._('Електронна пошта використовується як вхід при авторизації'),
        },
      ],
      labels: {
        rnokpp: this._('РНОКПП'),
        password: this._('Придумайте пароль'),
      },
    }
  },

  computed: {
    actualBankIdUrl() {
      /* eslint-disable-next-line */
      return this.bankidUrl + '?register_user_type=' + this.formdata.type
    },
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)

      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()

      if (!isValid) return

      this.submit(isValid, this.formdata)
    },

    async send(data) {
      data.phone = clearPhone(data.phone)

      return registerInvestor.execute({}, data).then(() => {
        window.dataLayer.push({
          event: 'formSuccess',
          eventCategory: 'becomeAnInvestor',
        })

        window.location.assign(prefixLanguage('/auth/register/success/'))
      })
    },
  },
}
</script>

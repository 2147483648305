<template lang="pug">
  include /mixins
  d-control-static-input(
    autocomplete="off"
    input-class="d-control-input--variant_5"
    :name='fieldName'
    :input-label="label"
    v-model="prettyValue"
    :rules="rules"
    :disabled="disabled || isPreview"
    :append-icon="appendIcon"
  )
    template(slot="appendIcon" slot-scope="{}")
      slot
</template>

<script>
import { prettifyPrice } from '@utils/filters'

export default {
  props: {
    value: {},
    label: {},
    fieldName: {},
    disabled: {},
    appendIcon: {},
    isPreview: {},
    rules: {
      default: 'required|numeric',
    },
  },

  data() {
    return {
      prettyValue: this.value,
    }
  },

  watch: {
    prettyValue: {
      handler(nval) {
        this.pretty(nval)
      },
    },
    value: {
      immediate: true,
      handler(nval) {
        this.pretty(nval)
      },
    },
  },

  methods: {
    pretty(val = 0) {
      if (val) {
        const clearValue = val.toString().replace(/[^\d]/g, '')
        this.prettyValue = prettifyPrice(clearValue)
        this.$emit('input', clearValue)
      } else {
        this.prettyValue = ''
        this.$emit('input', '')
      }
    },
  },
}
</script>

import {
  createResource,
  patchResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'cart'

const CART_UPDATE_LINK = prefixAPI('/update/', MODEL_PREFIX)
const CART_RETRIEVE_LINK = prefixAPI('/retrieve/', MODEL_PREFIX)

export const cartUpdateResource = createResource(CART_UPDATE_LINK, patchResource)
export const cartRetrieveResource = createResource(CART_RETRIEVE_LINK)

import {
  createResource,
  postResource,
  getResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const CREATE_CALLBACK = prefixAPI('communication/callback/')
const JOIN_US_URL = prefixAPI('communication/join/')
const SUBSCRIBE = prefixAPI('communication/subscribe/')
const FAQ = prefixAPI('faq/')
const CONTACT = prefixAPI('communication/contact/')

export const sendCallback = createResource(CREATE_CALLBACK, postResource)
export const sendJoinUs = createResource(JOIN_US_URL, postResource)
export const subscribe = createResource(SUBSCRIBE, postResource)
export const faq = createResource(FAQ, getResource)
export const contact = createResource(CONTACT, postResource)

export const cardsHeight = el => {
  // Скрипт на одинаковую высоту карточек
  const arrayBlocks = Array.from(document.getElementsByClassName(el))
  const arrayHeight = []
  let maxHeigh = 0;
  arrayBlocks.forEach(elem => {
    arrayHeight.push(elem.clientHeight)
  })
  maxHeigh = Math.max(...arrayHeight)
  arrayBlocks.forEach(elem => {
    elem.style.height = String(`${maxHeigh}px`)
  })
  maxHeigh = 0
}

<script>
export default {
  name: 'ConfirmModalTrigger',

  props: {
    callback: {},
    title: {},
    subtitle: {},
    info: {},
    cancelButtonText: {},
    confirmButtonText: {},
    classes: {},
    url: {
      type: String,
      default: () => null,
    },
  },

  methods: {
    openModal() {
      const modal = () => import('@app/UiComponents/UiConfirmModal')
      this.$modal.show(modal, {
        callback: this.callback,
        title: this.title,
        info: this.info,
        subtitle: this.subtitle,
        cancelButtonText: this.cancelButtonText,
        confirmButtonText: this.confirmButtonText,
      },
      {
        height: 'auto',
        classes: this.classes,
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      openConfirmModal: this.openModal,
    })
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tag',{attrs:{"tag":_vm.loaderController,"resource":_vm.resource},on:{"result":function($event){return _vm.$emit('result', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var receive = ref.receive;
var loading = ref.loading;
var result = ref.result;
return [_c('tag',{attrs:{"tag":_vm.paginationController,"resultPagination":result && result.pagination,"receiver":receive,"parameters":_vm.parameters},on:{"update:parameters":_vm.changeParameters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var filters = ref.filters;
var changeFilters = ref.changeFilters;
var pagination = ref.pagination;
var changePagination = ref.changePagination;
return [_vm._t("default",null,{"parameters":_vm.parameters,"filters":filters,"changeFilters":changeFilters,"pagination":pagination,"changePagination":changePagination,"receive":receive,"loading":loading,"result":result})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
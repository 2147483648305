function scrollFunction() {
  const header = document.querySelector('.js-header')
  if (header) {
    const offset = 60
    if (document.body.scrollTop > offset ||
      document.documentElement.scrollTop > offset) {
      header.classList.add('is-active')
    } else {
      header.classList.remove('is-active')
    }
  }
}

export const scrolly = () => {
  // Scroll to Up

  scrollFunction()

  window.addEventListener('scroll', scrollFunction, { passive: true })
}

import SelectWidget from '../Widgets/SelectWidget'
import MultipleSelectWidget from '../Widgets/MultipleSelectWidget'
import CheckboxWidget from '../Widgets/CheckboxWidget'
import SliderWidget from '../Widgets/SliderWidget'
import AutocompleteMultiselectWidget from '../Widgets/AutocompleteMultiselectWidget'
import InputWidget from '../Widgets/InputWidget'
import SearchWidget from '../Widgets/SearchWidget'
import BooleanWidget from '../Widgets/BooleanWidget'
import LocationWidget from '../Widgets/LocationWidget'
import DateRangeWidget from '../Widgets/DateRangeWidget'
import BooleanSelect from '../Widgets/BooleanSelect'
import MultipleCheckboxFilter from '../Widgets/MultipleCheckboxFilter'
import MultipleCheckboxFilterAdmin from '../Widgets/MultipleCheckboxFilterAdmin'

export const WIDGETS_MAP = {
  StandardRangeFilter: SliderWidget,
  input: InputWidget,
  search: SearchWidget,
  BooleanFilter: BooleanWidget,
  BooleanSelect,
  checkbox: CheckboxWidget,
  LocationWidget,
  DateFromToRangeFilter: DateRangeWidget,
  AutocompleteChoiceFilter: AutocompleteMultiselectWidget,
  MultipleChoiceFilter: MultipleSelectWidget,
  ModelChoiceFilter: SelectWidget,
  ChoiceFilter: SelectWidget,
  MultipleCheckboxFilter,
  MultipleCheckboxFilterAdmin,
}

const rangeKeys = {
  0: 'after',
  1: 'before',
}

export default {
  widgetsMap: WIDGETS_MAP,

  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,
      }
    },

    preparedValues() {
      const localValues = JSON.parse(JSON.stringify(this.value))
      const valuesKeys = Object.keys(localValues)

      return valuesKeys.reduce((acc, key) => {
        if (key.includes('after') || key.includes('before')) {
          Object.keys(rangeKeys).forEach(index => {
            if (key.includes(rangeKeys[index])) {
              /**
               * Remove `before` and `after` postfixes from attribute name
               */
              const clearKey = key.replace(`_${rangeKeys[index]}`, '')

              /**
               * Create a new array if it doesn't exist
               */
              if (!acc[clearKey]) {
                acc[clearKey] = []
              }

              /**
               * Add min and max values to an array of range filter by right indexes
               */
              acc[clearKey][index] = localValues[key]
            }
          })
        } else {
          acc[key] = localValues[key]
        }

        return acc
      }, {})
    },
  },

  methods: {
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },

    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },

    async updateValueImmediate(key, value) {
      this.$emit('input', { ...this.value, [key]: value })

      await this.$nextTick()

      this.$emit('submit:immediate')
    },

    updateMultipleValues(key, values) {
      const res = {}
      const [min, max] = values

      res[`${key}_after`] = min
      res[`${key}_before`] = max

      this.$emit('input', { ...this.value, ...res })
    },
  },
}

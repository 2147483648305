import aos from 'aos'
import shareon from 'shareon'
import { scrolly } from './ui-scripts'
import { UiCounter } from './counter'
import { logoClick } from './logo'
import { cardsHeight } from './cardHeight'
import { largeWord } from './titleFirstWord'
import { share } from './share'
import { partnersDots } from './mapsDots'
import { playVideo } from './playVideo'
import { tridentLoader } from './trident'

const cardsClasses = [
  'team-info-wrap',
  'adventage-card',
  'offer-card-margin',
  'news-card',
]

document.addEventListener('DOMContentLoaded', () => {
  tridentLoader()
  scrolly()
  logoClick()
  largeWord()
  shareon()
  cardsClasses.forEach(className => cardsHeight(className))
  shareon()
  share()
  partnersDots(0)
  playVideo()
  const windowWidth = 1200
  if (window.innerWidth < windowWidth) {
    UiCounter()
  }
})

aos.init({
  once: true,
  disable: () => {
    const maxWidth = 1200
    return window.innerWidth < maxWidth
  },
})

document.addEventListener('aos:in:counterCards', () => {
  UiCounter()
})

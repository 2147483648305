<template lang="pug">
  include /mixins.pug
  +b.ds-section.--space_xl.--space_4xl-xl.--bg_cover.--fullscreen(:style='`background-image: url("${backgroundUrl}")`')
    +e.element.--offset_vertical
      +b.g-container.--fit_lg
        +b.i-card
          +b.ds-panel.--space_xs.--space_sm-xl
            +e.element.--offset_bottom
              +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_lg.--size_xl-xl.--bold {{ _('Встановіть новий пароль') }}
          +b.ds-panel.--space_xl.--space_2xl-xl
            +e.element.--offset_bottom
              +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_md.--size_2md-xl {{ _('Встановіть новий пароль текст') }}
          +b.VALIDATION-OBSERVER(
            @submit.prevent='prepareData',
            ref="validator"
            tag="form"
          )
            +b.g-row.--appearance_spaced.--space_xl.--align_end.--justify_end
              +b.g-cell.g-cols.--12-xs.--9-xl
                +b.g-row.--appearance_spaced.--space_xl
                  cell(
                    v-for='field in fields'
                    :cols='field.cols || "12-xs 6-sm"'
                    :key='field.name'
                  )
                    +b.TAG.d-control-input--variant_dark(
                      autocomplete="off"
                      :tag='field.tag || "d-control-static-input"'
                      :name='field.name'
                      :rules='field.rules'
                      :ref='field.name'
                      :type='field.type || "text"'
                      :input-label='field.label'
                      v-model='formdata[field.name]'
                      :vid="field.name"
                    )
              +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--3-xl
                +b.BUTTON.control-button.--rounded(@click.prevent="prepareData")
                  +e.element
                    +e.SPAN.text {{ _('Зберегти') }}
              validation-provider(
                slim
                v-slot="{errors}"
                name="nonFieldErrors"
              )
                +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
                  +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default {{ errors[0] }}
              validation-provider(
                slim
                v-slot="{errors}"
                name="uid"
              )
                +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
                  +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default {{ _("Идентификатор пользователя не валидный, попробуйте снова отправить письмо для восстановления пароля.") }}
              validation-provider(
                slim
                v-slot="{errors}"
                name="token"
              )
                +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
                  +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default {{ _("Токен не валидный, попробуйте снова отправить письмо для восстановления пароля.") }}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { passwordSet } from '@api/auth.service'
import { AnswerMessage } from '@utils/submit'

export default {
  name: 'AuthCooperation',

  mixins: [FormMixin],

  props: {
    uid: {},
    token: {},
    backgroundUrl: {},
    authUrl: {},
  },

  data() {
    return {
      captchaConfig: {
        isActive: true,
        action: 'staff',
      },
      formdata: {
        uid: this.uid,
        token: this.token,
      },
      fields: [
        {
          name: 'newPassword1',
          type: 'password',
          rules: 'required|max:32|min:8',
          label: this._('Придумайте пароль'),
          tag: 'd-control-static-input',
        },
        {
          name: 'newPassword2',
          type: 'password',
          rules: { confirmed: 'newPassword1', required: true, max: 32, min: 8 },
          label: this._('Повторите пароль'),
          tag: 'd-control-static-input',
        },
      ],
    }
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()
      if (!isValid) return
      this.submit(isValid, this.formdata)
    },

    send(data) {
      return passwordSet.execute({}, data).then(() => {
        const message = {
          message: {
            title: this._('Пароль был успешно установлен. title'),
            text: this._('Пароль был успешно установлен. text'),
          },
        }
        const closeEvent = () => {
          window.location = this.authUrl
        }
        AnswerMessage(message, this, closeEvent)
      })
    },
  },
}
</script>
